import { takeLatest, takeLeading } from "redux-saga/effects";
import { customerPageRequestedSaga, customerSelectSaga } from "./customer.saga";
import { CustomerActionTypes } from "./customer.types";

export function* customerSaga() {
  yield takeLeading(
    CustomerActionTypes.CustomerPageRequested,
    customerPageRequestedSaga
  );
  yield takeLatest(CustomerActionTypes.CustomerSelection, customerSelectSaga);
}
