import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { LeaveRecsQuery } from "./leaves.model";
import {
  leavePageLoaded,
  leavePageToggleLoading,
  leavesRequested,
} from "./leaves.actions";
import { getLeaveRecords } from "./leaves.services";
import { ActionTypes, LeaveState } from "./leaves.types";
import { constructErrorMessage } from "@biznessforce/ui-lib";

export function* leavesPageRequestedSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: LeaveRecsQuery } };

  const { lastQuery }: { lastQuery: LeaveRecsQuery } = yield select(
    (state: { leaves: LeaveState }) => state.leaves
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(leavePageToggleLoading(true));

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };

  try {
    const { data, headers } = yield getLeaveRecords(finalQuery);
    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 1
    ) {
      yield put(leavesRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        leavePageLoaded({
          query: finalQuery,
          leaveRecords: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    yield put(
      updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}
