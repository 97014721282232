import {
  constructErrorMessage,
  formatDate,
  removeEmptyProps,
} from "@biznessforce/ui-lib";
import {
  concat,
  difference,
  flattenDeep,
  groupBy,
  isEqual,
  uniqWith,
  xor,
} from "lodash";
import dayjs from "dayjs";
import { all, delay, put, select } from "redux-saga/effects";
import { getEmployeeVerifiedInfo } from "../billing/billing.services";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { assignProjToEmp } from "../project/project.service";
import {
  AssignProject,
  EmpAvailabilities,
  EmpQuery,
  EmployeeCellBase,
  OverviewModel,
  OverviewQuery,
} from "./Overview.model";
import {
  empAssignLoading,
  empAvblLoaded,
  empAvblRequested,
  overviewLoaded,
  overviewPageToggleLoading,
  overviewRequested,
  updatePrjAsgndDatesState,
  updatePrjRmvdDatesState,
} from "./overview.actions";
import { fetchOverview, getEmpAvailability } from "./overview.service";
import { ActionTypes, OverviewState, TYPE_OF_OVERVIEW } from "./overview.types";

const WEEK_VALUE = { Mon: 0, Tue: 1, Wed: 2, Thu: 3, Fri: 4, Sat: 5, Sun: 6 };

export function* OverviewPageRequestedSaga(action: ActionTypes) {
  const {
    payload: { query, type },
  } = action as { payload: { query: OverviewQuery; type: TYPE_OF_OVERVIEW } };

  const { lastQuery }: { lastQuery: OverviewQuery } = yield select(
    (state: { overviews: OverviewState }) => state.overviews
  );

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };
  // console.log({ query, finalQuery });
  if (!finalQuery.fromDt) return;
  if (type === "PROJECT") delete finalQuery["pbcId"];

  try {
    yield put(ajaxLoader({ isLoading: true }));
    yield put(overviewPageToggleLoading(true));

    const { data, headers } = yield fetchOverview(
      { ...finalQuery, dur: 7 },
      type
    );

    let empVerifiedObjects = {};

    if (type === "ATTENDANCE") {
      const { data: verifiedEmployees } = yield getEmployeeVerifiedInfo(
        data.map((emp: { empId: string }) => emp.empId),
        finalQuery.pbcId!
      );
      empVerifiedObjects = verifiedEmployees;
    }
    const updatedData = data.map(
      (d: { cells: OverviewModel[]; empId: string; empName: string }) => {
        const cells = d.cells;
        let { ...emp } = d;

        if (empVerifiedObjects[emp.empId]) {
          emp = { ...emp, ...empVerifiedObjects[emp.empId] };
        }

        // TODO: testing for multiple projects
        // cells.map(cell => (cell.projects = cell.projects?.concat(cell.projects)));
        if (type === "ATTENDANCE") cells.push({ total: d["total"] });
        cells.unshift(emp);
        return cells;
      }
    );

    yield put(
      overviewLoaded({
        overviews: updatedData,
        query: finalQuery,
        totalCount: parseInt(headers["x-total-count"]),
      })
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    yield put(updateGlobalError(undefined));
  } catch (error) {
    console.log({ error });
    yield put(
      updateGlobalError({
        type: "error",
        msg: constructErrorMessage(error),
      })
    );
  } finally {
    yield put(ajaxLoader({ isLoading: false }));
  }
}

export function* AssignEmpToProjSaga(action: ActionTypes) {
  const {
    payload: { assignPayload },
  } = action as { payload: { assignPayload: AssignProject } };

  const {
    projectAssignedDates,
    projectRemovedDates,
    empAvailabilities,
    lastQuery,
    empQuery,
  }: {
    projectAssignedDates: string[];
    projectRemovedDates: string[];
    empAvailabilities;
    lastQuery: OverviewQuery;
    empQuery: EmpQuery;
  } = yield select((state: { overviews: OverviewState }) => state.overviews);

  yield put(ajaxLoader({ isLoading: true }));
  yield put(empAssignLoading(true));

  const staticProjectAssignedDates = flattenDeep(
    Object.values(empAvailabilities)
  )
    .filter((cellData) => cellData?.type !== "DUMMY")
    .filter((cellData) => cellData?.projects?.length)
    .map((cellData) => cellData?.cellDtStr);

  const xorDates = xor(staticProjectAssignedDates, projectAssignedDates);
  const finalAssignDates: string[] = xor(xorDates, projectRemovedDates);

  try {
    const payload: AssignProject = {
      ...assignPayload,
      shiftId:
        assignPayload.shiftId === "none" ? undefined : assignPayload.shiftId,
      siteId:
        assignPayload.siteId === "none" ? undefined : assignPayload.siteId,
      assignDateStrs: finalAssignDates.map((dateString) =>
        formatDate(dateString)
      ),
      removeDateStrs: projectRemovedDates.map((dateString) =>
        formatDate(dateString)
      ),
    };
    yield assignProjToEmp(removeEmptyProps(payload));

    // Refreshing Employee Availability & Overview API
    yield all([
      put(empAvblRequested(empQuery)),
      put(overviewRequested(lastQuery, "PROJECT")),
    ]);
  } catch (error) {
    yield put(
      updateGlobalError({
        type: "error",
        msg: constructErrorMessage(error),
      })
    );
  } finally {
    yield put(empAssignLoading(false));
    yield put(ajaxLoader({ isLoading: false }));
  }
}

export function* EmpAvlPageRequestedSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: EmpQuery } };

  yield put(ajaxLoader({ isLoading: true }));
  // Resetting the state
  yield put(updatePrjAsgndDatesState([]));
  yield put(updatePrjRmvdDatesState([]));
  yield put(empAvblLoaded({}, query));

  try {
    const { data }: { data: EmpAvailabilities } = yield getEmpAvailability(
      query
    );
    if (data.cells) {
      const alreadyAssignedDates: string[] = data.cells
        .filter((cell) => cell.projects?.length)
        .map((cell) => cell.cellDtStr as string);

      const monthBasedGroup = groupBy(data.cells, (cell) =>
        formatDate(cell.cellDtStr as string, "MMM YYYY")
      );

      Object.keys(monthBasedGroup).forEach((key) => {
        if (
          WEEK_VALUE[
            formatDate(monthBasedGroup[key][0].cellDtStr as string, "ddd")
          ]
        ) {
          const values = [...monthBasedGroup[key]];
          const iteratee = new Array(
            WEEK_VALUE[
              formatDate(monthBasedGroup[key][0].cellDtStr as string, "ddd")
            ]
          )
            .fill(0)
            .map((_, i) => {
              const customDate = query.fromDt
                ?.replaceAll("-", "/")
                .split("/")
                .reverse()
                .join("/") as string;

              return {
                type: "DUMMY",
                cellDt: new Date(
                  dayjs(customDate)
                    .subtract(i + 1, "day")
                    .toDate()
                ).getTime(),
                cellDtStr: formatDate(
                  dayjs(customDate).subtract(i + 1, "day"),
                  "DD-MMM-YYYY"
                ),
              };
            })
            .reverse();

          monthBasedGroup[key] = iteratee.concat(values);
        }
      });
      yield put(
        updatePrjAsgndDatesState(uniqWith(alreadyAssignedDates, isEqual))
      );
      yield put(empAvblLoaded(monthBasedGroup, query));
    }
  } catch (error) {
    yield put(
      updateGlobalError({
        type: "error",
        msg: constructErrorMessage(error),
      })
    );
  } finally {
    yield put(ajaxLoader({ isLoading: false }));
  }
}

export function* SelectEmpAvailabilitiesSaga(action: ActionTypes) {
  yield put(ajaxLoader({ isLoading: true }));

  const {
    payload: { type, checked },
  } = action as { payload: { type: "DAYS" | "SUN"; checked: boolean } };

  const {
    empAvailabilities,
    projectAssignedDates,
  }: { empAvailabilities; projectAssignedDates } = yield select(
    (state: { overviews: OverviewState }) => state.overviews
  );

  let finalDates: string[] = [];

  /**
   * Filtering All Available Dates Without Project Assigned
   */
  if (type === "DAYS") {
    const projectDates = flattenDeep(Object.values(empAvailabilities))
      .filter((cell: EmployeeCellBase) => cell.type !== "DUMMY")
      .filter((cell: EmployeeCellBase) => cell.projects)
      .map((cell: EmployeeCellBase) => cell.cellDtStr);

    const workingDaysDates = flattenDeep(Object.values(empAvailabilities))
      .filter((cell: EmployeeCellBase) => cell.type !== "DUMMY")
      .filter((cell: EmployeeCellBase) => !cell.projects)
      // .filter((cell: EmployeeCellBase) => formatDate(cell.cellDtStr!, "ddd") !== "Sun") // All Days should select Sunday aswell.
      .map((cell: EmployeeCellBase) => cell.cellDtStr);

    finalDates = checked
      ? concat(projectDates, workingDaysDates) // Add
      : concat(
          projectDates,
          difference(workingDaysDates, projectAssignedDates)
        ); // Remove
  } else if (type === "SUN") {
    const sunDayDates = flattenDeep(Object.values(empAvailabilities))
      .filter((cell: EmployeeCellBase) => cell.type !== "DUMMY")
      .filter((cell: EmployeeCellBase) => !cell.projects)
      .filter(
        (cell: EmployeeCellBase) => formatDate(cell.cellDtStr!, "ddd") === "Sun"
      )
      .map((cell: EmployeeCellBase) => cell.cellDtStr);
    finalDates = checked
      ? concat(projectAssignedDates, sunDayDates)
      : projectAssignedDates.filter(
          (cellDtStr: string) => formatDate(cellDtStr, "ddd") !== "Sun"
        );
  }

  yield put(updatePrjAsgndDatesState(uniqWith(finalDates, isEqual)));
  yield put(ajaxLoader({ isLoading: false }));
}

export function* UpdatePrjAsnDateSaga(action: ActionTypes) {
  const {
    payload: { date, isGreenCheckbox },
  } = action as { payload: { date: string; isGreenCheckbox: boolean } };

  const {
    projectAssignedDates,
    projectRemovedDates,
  }: { projectAssignedDates: string[]; projectRemovedDates: string[] } =
    yield select((state: { overviews: OverviewState }) => state.overviews);
  yield put(ajaxLoader({ isLoading: true }));

  if (isGreenCheckbox) {
    projectRemovedDates.includes(date)
      ? projectRemovedDates.splice(projectRemovedDates.indexOf(date), 1)
      : projectRemovedDates.push(date);

    yield put(updatePrjRmvdDatesState(projectRemovedDates));
  }

  /**
   * If Date Exists then remove from State else add it.
   */
  projectAssignedDates.includes(date)
    ? projectAssignedDates.splice(projectAssignedDates.indexOf(date), 1)
    : projectAssignedDates.push(date);

  yield put(updatePrjAsgndDatesState(uniqWith(projectAssignedDates, isEqual)));

  yield delay(250);
  yield put(ajaxLoader({ isLoading: false }));
}
