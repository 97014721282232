import { NoDataContainer } from "@biznessforce/ui-lib";
import { SidebarMenus } from "@constants/SidebarMenus";
import { Layout } from "antd";
import React, { Suspense, lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthorizedRoute, RouterSwitch } from "./utils.routes";

const DashboardLazy = lazy(() => import("../modules/Dashboard"));
const BillingLazy = lazy(() => import("../modules/Billing/billing.routes"));
const CustomersLazy = lazy(() => import("../modules/Customers"));
const DocumentsLazy = lazy(() => import("../modules/Documents"));
const EmployeesLazy = lazy(() => import("../modules/Employees"));
const InvoiceLazy = lazy(() => import("../modules/Invoice"));
const LeaveLazy = lazy(() => import("../modules/Leaves"));
const EmployeeOverviewLazy = lazy(() => import("../modules/EmployeeOverview"));
const PreferenceLazy = lazy(() => import("../modules/Preference"));
const ProjectsLazy = lazy(() => import("../modules/Projects"));
const TaskManagementLazy = lazy(() => import("../modules/TaskManagement"));
const MyCardsMainLazy = lazy(() =>
  import("../modules/TaskManagement/MyCardsMain")
);
const ProjectOverviewLazy = lazy(() =>
  import("../modules/ProjectOverview/ProjectRoster")
);
const DailyRosterLazy = lazy(() =>
  import("../modules/ProjectOverview/DailyRoster")
);
const ProjectDetailLazy = lazy(() =>
  import("../modules/Projects/components/ProjectDetailOverview")
);

function MainRoutes() {
  return (
    <Suspense
      fallback={
        <Layout>
          <NoDataContainer type="loading" />
        </Layout>
      }
    >
      <RouterSwitch>
        <Redirect exact from="/" to={`/${SidebarMenus.dashboard.key}`} />
        <Route
          path={`/${SidebarMenus.dashboard.key}`}
          component={DashboardLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.billing.permission}
          path={`/${SidebarMenus.billing.key}`}
          component={BillingLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.customers.permission}
          path={`/${SidebarMenus.customers.key}`}
          component={CustomersLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.documents.permission}
          path={`/${SidebarMenus.documents.key}`}
          component={DocumentsLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.employees.permission}
          path={`/${SidebarMenus.employees.key}`}
          component={EmployeesLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.invoice.permission}
          path={`/${SidebarMenus.invoice.key}`}
          component={InvoiceLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.leaves.permission}
          path={`/${SidebarMenus.leaves.key}`}
          component={LeaveLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.empOverview.permission}
          path={`/${SidebarMenus.empOverview.key}`}
          component={EmployeeOverviewLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.preference.permission}
          path={`/${SidebarMenus.preference.key}`}
          component={PreferenceLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.projectOverview.permission}
          path={`/${SidebarMenus.projectOverview.children[0].key}`}
          component={DailyRosterLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.projectOverview.permission}
          path={`/${SidebarMenus.projectOverview.children[1].key}`}
          component={ProjectOverviewLazy}
        />
        <AuthorizedRoute
          allowedRoles={SidebarMenus.projects.permission}
          path={`/${SidebarMenus.projects.key}`}
          component={ProjectsLazy}
        />
        <AuthorizedRoute
          exact
          allowedRoles={SidebarMenus.projects.permission}
          path={`/project/detail/:id/:tabId`}
          component={ProjectDetailLazy}
        />
        <AuthorizedRoute
          exact
          allowedRoles={SidebarMenus.kanban.permission}
          path={`/${SidebarMenus.kanban.key}`}
          component={TaskManagementLazy}
        />
        <AuthorizedRoute
          path="/kanban/cards"
          exact
          component={MyCardsMainLazy}
          allowedRoles={SidebarMenus.kanban.permission}
        />
      </RouterSwitch>
    </Suspense>
  );
}

export default MainRoutes;
