import {
  ArrowDownOutlined,
  BulbOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

/** Clamps a number between zero and a maximum. */
const clamp = (value, max) => {
  return Math.max(0, Math.min(max, value));
};

/**
 * Moves an item one index in an array to another.
 * @param array Array in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.
 */
const moveItemInArray = (array, fromIndex, toIndex) => {
  return new Promise((resolve, reject) => {
    let newArray = [...array];
    const from = clamp(fromIndex, newArray.length - 1);
    const to = clamp(toIndex, newArray.length - 1);

    if (from === to) {
      resolve([...newArray]);
      return;
    }

    const target = newArray[from];
    const delta = to < from ? -1 : 1;

    for (let i = from; i !== to; i += delta) {
      newArray[i] = newArray[i + delta];
    }
    newArray[to] = target;

    resolve([...newArray]);
  });
};

/**
 * Moves an item from one array to another.
 * @param currentArray Array from which to transfer the item.
 * @param targetArray Array into which to put the item.
 * @param currentIndex Index of the item in its current array.
 * @param targetIndex Index at which to insert the item.
 */
const transferArrayItem = (
  currentArray,
  targetArray,
  currentIndex,
  targetIndex
) => {
  return new Promise((resolve, reject) => {
    const from = clamp(currentIndex, currentArray.length - 1);
    const to = clamp(targetIndex, targetArray.length);

    if (currentArray.length) {
      targetArray.splice(to, 0, currentArray.splice(from, 1)[0]);
      resolve({
        currentArray,
        targetArray,
      });
    } else {
      reject("Current array is empty.");
    }
  });
};

const PriorityFormOptions = [
  {
    label: "Urgent",
    icon: <NotificationOutlined className="text-danger" />,
  },
  {
    label: "Important",
    icon: <ExclamationCircleOutlined className="text-danger" />,
  },
  {
    label: "Medium",
    icon: <BulbOutlined className="text-warning" />,
  },
  {
    label: "Low",
    icon: <ArrowDownOutlined className="text-ternary" />,
  },
];

const getKanbanCardPriorityClass = (priority) => {
  return priority === "Low"
    ? "kanban-card-low"
    : priority === "Medium"
    ? "kanban-card-medium"
    : priority === "Important"
    ? "kanban-card-important"
    : priority === "Urgent"
    ? "kanban-card-urgent"
    : "kanban-card-default";
};

export {
  getKanbanCardPriorityClass,
  moveItemInArray,
  PriorityFormOptions as priorityDropdownOptions,
  transferArrayItem,
};
