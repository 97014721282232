import { CoreConfigActionTypes } from "./config.actions";

const initialCoreState = {
  ajaxLoader: {
    isLoading: false,
    theme: "dark",
  },
  globalAlert: false, // Alert Notice
  globalError: undefined, // Error Handling
};

export const reducer = (state = initialCoreState, action) => {
  switch (action.type) {
    case CoreConfigActionTypes.ajaxLoader: {
      const { ajaxLoader } = action.payload;
      return { ...state, ajaxLoader: { ...state.ajaxLoader, ...ajaxLoader } };
    }
    case CoreConfigActionTypes.globalAlert: {
      const { globalAlert } = action.payload;
      return { ...state, globalAlert };
    }
    case CoreConfigActionTypes.updateGlobalError: {
      const { globalError } = action.payload;
      return { ...state, globalError: globalError };
    }
    case CoreConfigActionTypes.clearCoreConfig: {
      return { ...initialCoreState };
    }
    default:
      return { ...state };
  }
};
