import {
  AssignProject,
  EmpQuery,
  OverviewModel,
  OverviewQuery,
} from "./Overview.model";

export const OVERVIEW_TOTAL_ROW_COUNT = 25;
export const OVERVIEW_TOTAL_COL_COUNT = 7;

export type TYPE_OF_OVERVIEW = "ATTENDANCE" | "PROJECT";

export interface OverviewState {
  listLoading: boolean;
  totalCount: number;
  lastQuery: OverviewQuery;
  overviews: OverviewModel[][] | undefined;
  empQuery: EmpQuery;
  empAvailabilities: any;
  empAssignLoading: boolean;
  projectAssignedDates: string[];
  projectRemovedDates: string[];
  attendaceShowHideValues: string[];
}

export const OverviewActionTypes = {
  OverviewPageRequested: "[OVERVIEW] Page Requested [API]",
  OverviewPageLoaded: "[OVERVIEW] Page Loaded [ACTION]",
  OverviewPageToggleLoading: "[OVERVIEW] Page Toggle Loading [ACTION]",
  EmpAvblPageRequested: "[OVERVIEW EMP AVBLT] page requested [API]",
  EmpAvblPageLoaded: "[OVERVIEW EMP AVBLT] page loaded [API]",
  SelectEmpAvailabilies:
    "[OVERVIEW EMP AVBLT] Select Employee Availabilties [ACTION]",
  AssingEmpToProj: "[OVERVIEW EMP ASSIGN] Assign Project to Emplyee [API]",
  AssignEmpToProjLoading: "[OVERVIEW EMP ASSIGN] Assign Loading [ACTION]",
  UpdatePrjAsgndDate:
    "[OVERVIEW EMP ASSIGN] Update Date to Project Assigned [ACTION]",
  UpdatePrjAsgndDatesState:
    "[OVERVIEW EMP ASSIGN] Update UpdatePrjAsgndDate State [ACTION]",
  UpdatePrjRmvdDate:
    "[OVERVIEW EMP REMOVE] Update Date to Project Removed [ACTION]",
  UpdatePrjRmvdDatesState:
    "[OVERVIEW EMP REMOVE] Update UpdatePrjRmvdDate State [ACTION]",
  ClearOverviewState: "[OVERVIEW] Clear Overview State [ACTION]",
  UpdateAttendanceShowHideState:
    "[ATTENDACE] Toggle attendance ShowHide [ACTION]",
};

interface OverviewPageRequested {
  type: typeof OverviewActionTypes.OverviewPageRequested;
  payload: { query: OverviewQuery; type: TYPE_OF_OVERVIEW };
}

interface OverviewPageLoaded {
  type: typeof OverviewActionTypes.OverviewPageLoaded;
  payload: {
    overviews: OverviewModel[][];
    query: OverviewQuery;
    totalCount: number;
  };
}

interface OverviewPageToggleLoading {
  type: typeof OverviewActionTypes.OverviewPageToggleLoading;
  payload: { isLoading: boolean };
}

interface AssignEmpToProjLoading {
  type: typeof OverviewActionTypes.AssignEmpToProjLoading;
  payload: { isLoading: boolean };
}

interface EmpAvblPageRequested {
  type: typeof OverviewActionTypes.EmpAvblPageRequested;
  payload: { query: EmpQuery };
}

interface EmpAvblPageLoaded {
  type: typeof OverviewActionTypes.EmpAvblPageLoaded;
  payload: { empAvailabilities: any; query: EmpQuery };
}

interface UpdatePrjAsgndDate {
  type: typeof OverviewActionTypes.UpdatePrjAsgndDate;
  payload: { date: string; checked: boolean; isGreenCheckbox: boolean };
}

interface UpdatePrjAsgndDatesState {
  type: typeof OverviewActionTypes.UpdatePrjAsgndDatesState;
  payload: { dates: string[] };
}

interface UpdatePrjRmvdDatesState {
  type: typeof OverviewActionTypes.UpdatePrjRmvdDatesState;
  payload: { dates: string[] };
}

interface SelectEmpAvailabilies {
  type: typeof OverviewActionTypes.SelectEmpAvailabilies;
  payload: { type: "DAYS" | "SUN"; checked: boolean };
}

interface AssingEmpToProj {
  type: typeof OverviewActionTypes.AssingEmpToProj;
  payload: { assignPayload: AssignProject };
}

interface ClearOverviewState {
  type: typeof OverviewActionTypes.ClearOverviewState;
  payload: {};
}

interface UpdateAttendanceShowHide {
  type: typeof OverviewActionTypes.UpdateAttendanceShowHideState;
  payload: { showHideValues: { key: string; value: string }[] };
}

export type ActionTypes =
  | OverviewPageRequested
  | OverviewPageLoaded
  | OverviewPageToggleLoading
  | EmpAvblPageRequested
  | EmpAvblPageLoaded
  | UpdatePrjAsgndDate
  | UpdatePrjAsgndDatesState
  | UpdatePrjRmvdDatesState
  | SelectEmpAvailabilies
  | AssignEmpToProjLoading
  | AssingEmpToProj
  | ClearOverviewState
  | UpdateAttendanceShowHide;
