import { AuthActionTypes } from './auth.types';

export const login = (loggedUser) => ({
  type: AuthActionTypes.Login,
  payload: { loggedUser },
});

export const requestUser = (user) => ({
  type: AuthActionTypes.UserRequested,
  payload: { user },
});

export const fulfillUser = (user) => ({
  type: AuthActionTypes.UserLoaded,
  payload: { user },
});

export const logout = ({ timedOut }) => ({
  type: AuthActionTypes.Logout,
  payload: { timedOut },
});

export const refreshToken = () => ({ type: AuthActionTypes.RefreshToken });

export const updateDeviceInfo = (deviceInfo) => ({
  type: AuthActionTypes.UpdateDeviceInfo,
  payload: { deviceInfo },
});

export const updateToken = ({ authToken, refreshToken }) => ({
  type: AuthActionTypes.UpdateToken,
  payload: { authToken, refreshToken },
});

export const resetAuthReducer = () => ({
  type: AuthActionTypes.ResetAuthReducer,
});
