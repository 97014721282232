export type Customer = {
  createdBy: string;
  createdDate: number;
  createdDateStr: number;
  partyId: string;
  name: string;
  alias: string;
  code: string;
  status: string;
  mobile: string;
  phone: string;
  emailId: string;
  billAddrId: string;
  billAddr: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    phone: string;
    postalCode: string;
  };
};

// type CustomerDoc = {
//   docId: string;
//   type: string;
//   docNo: string;
//   status: string;
// };

export class CustomerQuery {
  filter?: string = "";
  pageNumber?: number = 1;
  pageSize?: number = 20;
  sortOrder?: string = "asc";
  sortField?: string = "p.createdDate";
}
