import localforage from "localforage";

/**
 * LocalForage
 */

localforage.config({
  drive: localforage.INDEXEDDB,
  name: "ezforce",
  version: 1.0,
  description: "Application Configuration",
});

export const setStorage = async (key, value) => {
  try {
    await localforage.setItem(key, value);
  } catch (error) {
    console.log("error", error);
  }
};

export const getStorage = async (key) => {
  try {
    return await localforage.getItem(key);
  } catch (error) {
    console.log("error", error);
  }
};

export const removeStorage = async (key) => {
  try {
    await localforage.removeItem(key);
  } catch (error) {
    console.log("error", error);
  }
};
