import { removeEmptyProps } from "@biznessforce/ui-lib";
import { WFAxios } from "@api/api.instance";
import { OverviewQuery } from "../overview/Overview.model";

const WORKFORCE_BILLING_CONFIG_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/billing/cfg/api`;
const WORKFORCE_BILLING_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/billing/api`;

export const fetchProjectGroups = (filter: any) =>
  WFAxios.get(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/pbc`, {
    params: removeEmptyProps({ ...filter }),
  });

export const cancelProjectBillCycle = (payload: any) =>
  WFAxios.put(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/pbc/cancel`, payload);

export const removeProjectBillCycle = (pbcId: string) =>
  WFAxios.delete(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/pbc/${pbcId}`);

export const getRecsByEmp = ({
  pbcId,
  pbcbgId,
  bal,
}: {
  pbcId: string;
  pbcbgId?: string;
  bal?: boolean;
}) =>
  WFAxios.get(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/billgrp/recsbyemp`, {
    params: removeEmptyProps({ pbcId, pbcbgId, bal }),
  });

export const createBillGroup = (payload: any) =>
  WFAxios.post(`${WORKFORCE_BILLING_BASE_URL}/pbc/billgrp`, payload);

export const updateBillGroup = (payload: any) =>
  WFAxios.put(`${WORKFORCE_BILLING_BASE_URL}/pbc/billgrp`, payload);

export const getBillGrpsByBillCycle = (billCycleId: string) =>
  WFAxios.get(
    `${WORKFORCE_BILLING_BASE_URL}/pbc/billgrps?pbcId=${billCycleId}`
  );

export const deletePbcBillGroup = (billGroupId: string) =>
  WFAxios.delete(`${WORKFORCE_BILLING_BASE_URL}/pbc/billgrp`, {
    params: { pbcbgId: billGroupId },
  });

export const getBillGroupDetail = (billGroupId: string) =>
  WFAxios.get(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/billgrp/${billGroupId}`);

export const getEmployeeDetailByBillGrp = (
  pbcId: string,
  pbcbgId: string,
  empId: string
) => {
  const params = removeEmptyProps({ pbcId, pbcbgId, empId });
  return WFAxios.get(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/billgrp/emp/recs`, {
    params,
  });
};

export const verifyEmployeeByBillCycle = (billCycleId: string, payload: any) =>
  WFAxios.post(
    `${WORKFORCE_BILLING_BASE_URL}/pbc/emp/verify?pbcId=${billCycleId}`,
    payload
  );

export const unVerifyEmployeeByBillCycle = (
  billCycleId: string,
  payload: any
) =>
  WFAxios.post(
    `${WORKFORCE_BILLING_BASE_URL}/pbc/emp/unverify?pbcId=${billCycleId}`,
    payload
  );

export const getEmployeeVerifiedInfo = (
  empId: string | string[],
  pbcId: string
) => {
  return WFAxios.get(
    `${WORKFORCE_BILLING_BASE_URL}/pbc/empverifyinfo?empId=${empId}&pbcId=${pbcId}`
  );
};

export const getInvoiceList = (projectId: string) =>
  WFAxios.get(`${WORKFORCE_BILLING_BASE_URL}/pbc/invoices?pbcId=${projectId}`);

export const getInvoiceDetail = (invoiceId: string) =>
  WFAxios.get(`${WORKFORCE_BILLING_BASE_URL}/inv/${invoiceId}`);

export const recomputeBillGroup = (billCycleId: string) =>
  WFAxios.put(
    `${WORKFORCE_BILLING_BASE_URL}/pbc/recompute?pbcId=${billCycleId}`,
    {}
  );

export const downloadTimesheetAPI = ({
  pbcId,
  pbcbgId,
  invId,
  inclRate,
  ...restProps
}: OverviewQuery & {
  pbcbgId: string;
  invId: string;
  inclRate: string;
  bal: boolean;
}) => {
  const { siteId, bal } = restProps;

  return WFAxios.get(`${WORKFORCE_BILLING_BASE_URL}/report/timesheet/excel`, {
    params: removeEmptyProps({ pbcId, pbcbgId, invId, inclRate, siteId, bal }),
    responseType: "blob",
  });
};

export const downloadPayrollSummary = ({ fromDt, toDt, orgzId }: any) =>
  WFAxios.get(`${WORKFORCE_BILLING_BASE_URL}/report/payroll/summary/excel`, {
    params: removeEmptyProps({ fromDt, toDt, orgzId }),
    responseType: "blob",
  });

export const reviewPBC = (revert: boolean, payload: any) => {
  const params = {};
  if (revert) params["revert"] = true;
  return WFAxios.put(
    `${WORKFORCE_BILLING_CONFIG_BASE_URL}/pbc/review`,
    payload,
    {
      params,
    }
  );
};

export const updateRDC = (payload: any, revert: boolean) => {
  return WFAxios.put(`${WORKFORCE_BILLING_CONFIG_BASE_URL}/rdc`, payload, {
    params: removeEmptyProps({ revert }),
  });
};

export const getTimeCard = async (chargeGroupId: string, pos: number) => {
  const params = removeEmptyProps({ rdcgId: chargeGroupId, pos });

  return WFAxios.get(`${WORKFORCE_BILLING_BASE_URL}/tcard`, { params });
};
