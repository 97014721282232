import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { PageNotFound } from "@biznessforce/ui-lib";
import { Layout } from "antd";
import { hasAppPermissions } from "@constants/utils";
import AuthPage from "../modules/Auth/AuthPage";

// Check the App is Authenticated or Not
const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthorized } = useSelector(
    ({ auth }: any) => ({ isAuthorized: auth.authToken != null }),
    shallowEqual
  );

  const location = useLocation();

  return (
    <>
      {/* {!isAuthorized ? (
        <Route>
          <Layout>
            <AuthPage />
          </Layout>
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: "/auth/login",
            search: `?redirect=${location.pathname || "/"}`,
            state: { from: location.pathname || "/" },
          }}
        />
      )} */}
      <Route
        {...rest}
        render={() => {
          return isAuthorized ? (
            <>
              <Component />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                search: `?redirect=${location.pathname || "/"}`,
                state: { from: location.pathname || "/" },
              }}
            />
          );
        }}
      />
    </>
  );
};

// Allow Only Authorized Routes
const AuthorizedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { account } = useSelector(
    ({ auth: { account } }: any) => ({ account }),
    shallowEqual
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        return !allowedRoles ||
          hasAppPermissions(account?.authorities, allowedRoles) ? (
          <Component {...props} />
        ) : (
          <PageNotFound onBackClick={() => {}} />
        );
      }}
    />
  );
};

// RouteSwitch with pageNotFound Handled
const RouterSwitch = ({ children }) => (
  <Switch>
    {children}
    {/* Page Not Found */}
    <Route path="*">
      <PageNotFound onBackClick={() => {}} />
    </Route>
  </Switch>
);

export { AuthenticatedRoute, AuthorizedRoute, RouterSwitch };
