import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { History } from "history";
import { ActionTypes, ProjectState } from "./project.types";
import {
  projectPageToggleLoading,
  projectSelected,
  projectsLoaded,
  projectsRequested,
} from "./project.actions";
import { findProjects, getProjectById } from "./project.service";
import { constructErrorMessage } from "@biznessforce/ui-lib";
import { ProjectQuery } from "./project.model";

export function* projectsPageRequestSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: ProjectQuery } };

  const { lastQuery }: { lastQuery: ProjectQuery } = yield select(
    (state: { projects: ProjectState }) => state.projects
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(projectPageToggleLoading(true));

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };

  try {
    const { headers, data } = yield findProjects(finalQuery);
    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 1
    ) {
      yield put(projectsRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        projectsLoaded({
          query: finalQuery,
          projects: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    yield put(
      updateGlobalError({
        type: "error",
        msg: constructErrorMessage(error),
      })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}

export function* projectSelectSaga(action: ActionTypes) {
  const {
    payload: { projectId, history },
  } = action as { payload: { projectId: string; history: History } };

  if (projectId) {
    try {
      const { data } = yield getProjectById(projectId);
      yield put(projectSelected(data));
      yield put(updateGlobalError(undefined));
      if (history) {
        // noinspection JSVoidFunctionReturnValueUsed
        yield history.push(`/project/detail/${projectId}/info`);
      }
    } catch (error) {
      yield put(
        updateGlobalError({
          type: "error",
          msg: constructErrorMessage(error),
        })
      );
    }
  }
}
