// {{wfproj-url-prefix}}/api/overview?size=30&sort=,DESC&fromDt=01-02-2021&dur=7&page=0&projName=Random&empName=Dallas

import { OVERVIEW_TOTAL_ROW_COUNT } from "./overview.types";
import { TotalCellType } from "./CellBody.model";
import dayjs from "dayjs";

export type Alert = {
  level: { name: string; colorHex: string };
  msg: string;
  refUuid: string;
  type: string;
};

export type DialogTypes =
  | "VIEW"
  | "EDIT"
  | "ASSIGN"
  | "RENEW"
  | "REMOVE"
  | "LEAVE"
  | "BULK_ASSIGN"
  | "NO_WORK"
  | "CLEAR_NOWORK"
  | "MARK_SUPERVISOR"
  | "MARK_SUPERVISOR_EDIT";

export type ProjectAttendance = {
  present: boolean;
  inDateTimeStr?: string;
  outDateTimeStr?: string;
  regMins?: number;
  reg?: string;
  regHr?: any;
  ot1Mins?: number;
  ot1?: string;
  ot1Hr?: any;
  ot2Mins?: number;
  ot2?: string;
  ot2Hr?: any;
  brkMins?: number;
  brk?: string;
  brkHr?: any;
  noBrk?: boolean;
  remarks?: string;
  compHrModBy?: string;
  compHrModTimeStr?: string;
  compHrModRsn?: string;
  computedHrMod?: string;
  computedHrModRsn?: string;
  jobNo?: string;
  timeInBy?: {
    accountId: string;
    login?: string;
    personName?: string;
  };
  timeOutBy?: {
    accountId: string;
    login?: string;
    personName?: string;
  };
  timeInTimeStr: string;
  timeOutTimeStr: string;
};

export type OverviewProject = {
  allowEdit: boolean;
  allowEditMsg: string;
  autoRenewed: boolean;
  projId?: string;
  projName?: string;
  siteOff?: boolean;
  siteOffManual?: boolean;
  siteOffModBy?: string;
  siteOffRsn?: string;
  siteId?: string;
  siteName?: string;
  siteIncharge: boolean;
  siteInchargeId?: string;
  skillCode?: string;
  skillColor?: string;
  shiftId: string;
  shiftName: string;
  shiftColor: string;
  rosterDayId?: string;
  skillId?: string;
  skillName?: string;
  skillLevel?: number;
  bgColor?: string;
  borderColor?: string;
  colorCode?: string;
  attendance?: ProjectAttendance;
  remarks?: string;
};

export class HolidayCellModel {
  holidayId?: string;
  title?: string;
  description?: string;
}

export class LeaveCellModel {
  colorHex?: string;
  dayCnt?: number;
  dayType?: string;
  leaveId?: string;
  type?: string;
  reason?: string;
  status?: string;
  mc?: boolean;
}

export class EmployeeCellBase {
  type?: "DUMMY";
  cellDt?: number;
  cellDtStr?: string;
  noWork?: boolean;
  notAvble?: boolean;
  notAvbleReason?: string;
  projects?: OverviewProject[];
  resrPartnerAlias?: string;
  resrPartnerName?: string;
  resrPartnerId?: string;
  orgzAlias?: string;
  // attendance?: ProjectAttendance;
  leave?: LeaveCellModel;
  holiday?: HolidayCellModel;
  noWorkBy?: {
    accountId?: string;
    login?: string;
    personName?: string;
  };
}

export class OverviewModel extends EmployeeCellBase {
  alerts?: Alert[];
  empId?: string;
  rosterId?: string;
  empName?: string;
  empStatus?: {
    colorHex: string;
    name: string;
  };
  finNo?: string;
  idNo?: string;
  nationality?: string;
  idType?: string;
  ppNo?: string;
  wpNo?: string;
  availabilityMsg?: string;
  availabilityMsgDet?: string;
  available?: boolean;
  profilePhoto?: string;
  profilePhotoSignedUrl?: string;
  total?: TotalCellType;
  skills?: {
    skillId?: string;
    code?: string;
    name?: string;
    description?: string;
    level?: number;
    employeeId?: string;
  }[];
  status?: "Success" | "Error";
  statusMsg?: string;
  ownSkillId?: string;
  skillId?: string;
  skillLevel?: string;
  ownSkillLevel?: string;
  verifier?: any;
  verifiedOn?: any;
  tsUploaded?: any;
  tsUploadOn?: any;
  tsUploadBy?: {
    personName?: string;
  };
}

export class EmpAvailabilities {
  empId?: string;
  empName?: string;
  cells?: EmployeeCellBase[];
}

export type AssignProject = {
  empId: string;
  projectId: string;
  siteId?: string;
  shiftId?: string;
  skillId: string;
  skillLevel?: string;
  assignDates?: number[];
  removeDates?: number[];
  assignDateStrs?: string[];
  removeDateStrs?: string[];
};

export class EmpLeave {
  leaveId?: string;
  employeeId?: string;
  leaveType?: {
    colorHex: string;
    name: string;
  };
  subLeaveType?: string;
  fromDateStr?: string;
  fromDate?: number;
  fromDateType?: "Full" | "FirstHalf" | "SecondHalf";
  toDateStr?: string;
  toDate?: number;
  toDateType?: "Full" | "FirstHalf" | "SecondHalf";
  durDays?: number;
  reason?: string;
  status?: string;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export class EmpQuery {
  empUuid?: string;
  fromDt?: string;
  toDt?: string;
}

export class OverallEmployeeQuery {
  pageSize?: number = 35;
  pageNumber?: number = 1;
  sortOrder?: string = "asc";
  searchText?: string = "";
  dur?: number = 7;
  fromDt?: string;
  toDt?: string;
  sortField?: { id: string; value: string } = { id: "none", value: "None" };
  forProjId?: string = "";
  forShiftId?: string = "";
  forSiteId?: string = "";
  projId?: string = "";
  skillId?: string = "";
  ratingViewId?: string = "";
  srcDate?: string = "";
}

export class OverviewQuery {
  pageSize?: number = OVERVIEW_TOTAL_ROW_COUNT;
  pageNumber?: number = 0;
  sortOrder?: string = "asc";
  sortField?: { id: string; value: string } = { id: "none", value: "None" };
  dur?: number = 15;
  fromDt?: string = dayjs().subtract(3, "day").format("DD-MM-YYYY");
  toDt?: string;
  showIdle?: boolean;
  incharge?: boolean;
  projName?: string = "";
  empName?: string = "";
  skillIds?: string[] = [];
  siteId?: string = "";
  projIds?: any;
  leaveTypes?: string[] = [];
  partnerId?: string[] = [];
  orgId?: string[] = [];
  shiftIds?: string[] = [];
  // Billing Properties
  empIds?: string[];
  pbcId?: string;
}
