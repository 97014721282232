import { SplashScreen, useQueryParams } from "@biznessforce/ui-lib";
import { logout } from "@store/auth/auth.actions";
import React from "react";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function Logout() {
  let query = useQueryParams();
  const redirectQuery = query.get("redirect") || "/";

  const hasAuthToken = useSelector(
    (state) => state.auth.authToken,
    shallowEqual
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    localStorage.setItem("logout_msg", "Logged out manually.");
    dispatch(logout({ timedOut: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasAuthToken ? (
    <SplashScreen />
  ) : (
    <Redirect
      to={{
        pathname: "/auth/login",
        search: `?redirect=${redirectQuery}`,
        state: { from: redirectQuery },
      }}
    />
  );
}

export default Logout;
