import { removeEmptyProps } from "@biznessforce/ui-lib";
import { CustomerQuery } from "./customer.model";
import { WFAxios } from "@api/api.instance";

const constructParam = (payload: CustomerQuery) => ({
  searchTerm: payload.filter ? payload.filter.toLowerCase() : null,
  page: payload.pageNumber!.toString(),
  size: payload.pageSize!.toString(),
  sort:
    payload.sortField !== "none"
      ? `${payload.sortField},${payload.sortOrder!.toUpperCase()}`
      : null,
});

const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/bz/api`;

export const findCustomers = (query: CustomerQuery) => {
  const params = removeEmptyProps(constructParam(query));
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/v2/parties`, { params });
};

export const createCustomer = (customer) =>
  WFAxios.post(`${WORKFORCE_HR_BASE_URL}/party`, customer);

export const getCustomerByName = (partyId) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/party/${partyId}`);

export const deleteCustomer = (partyId) =>
  WFAxios.delete(`${WORKFORCE_HR_BASE_URL}/party/${partyId}`);

export const updateCustomer = (customer) =>
  WFAxios.put(`${WORKFORCE_HR_BASE_URL}/party`, customer);
