import { ProjOverviewQuery, ProjOverview } from "./proj_overview.model";

export interface ProjOverviewState {
  listLoading: boolean;
  lastQuery: ProjOverviewQuery;
  dataList: any;
}

export const ProjOverviewActionTypes = {
  ProjOverviewPageRequested: "[PROJ_OVERVIEW] Page Requested [API]",
  ProjOverviewPageLoaded: "[PROJ_OVERVIEW] Page Loaded [ACTION]",
  ProjOverviewPageToggleLoading: "[PROJ_OVERVIEW] Page Toggle Loading [ACTION]",
  ClearProjOverviewState:
    "[PROJ_OVERVIEW] Clear Project Overview State [ACTION]",
};

interface ProjOverviewPageRequested {
  type: typeof ProjOverviewActionTypes.ProjOverviewPageRequested;
  payload: { query: ProjOverviewQuery };
}

interface ProjOverviewPageLoaded {
  type: typeof ProjOverviewActionTypes.ProjOverviewPageLoaded;
  payload: { dataList: ProjOverview; query: ProjOverviewQuery };
}

interface ProjOverviewPageToggleLoading {
  type: typeof ProjOverviewActionTypes.ProjOverviewPageToggleLoading;
  payload: { isLoading: boolean };
}

interface ClearProjOverviewState {
  type: typeof ProjOverviewActionTypes.ClearProjOverviewState;
  payload: {};
}

export type ActionTypes =
  | ProjOverviewPageRequested
  | ProjOverviewPageLoaded
  | ProjOverviewPageToggleLoading
  | ClearProjOverviewState;
