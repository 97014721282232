import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { AuthActionTypes } from "./auth.types";

const initialAuthState = {
  account: undefined,
  authToken: undefined,
  refreshToken: undefined,
  orgToken: undefined,
  sessionId: undefined,
  deviceInfo: undefined,
};

export const authReducer = persistReducer(
  {
    storage,
    key: "v709-bzforce",
    whitelist: ["user", "authToken", "refreshToken", "orgToken"],
    // transforms: [
    //   encryptTransform({
    //     secretKey: "cbbe54dd-e73c-419b-8962-2d40706b4628",
    //     onError: (error) => {
    //       // Handle the error.
    //       console.log("Error while encrypting redux-persis", error);
    //     },
    //   }),
    // ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case AuthActionTypes.Login: {
        const {
          loggedUser: {
            jwt_token,
            refresh_token,
            account: { organizationId },
          },
        } = action.payload;
        return {
          ...state,
          authToken: jwt_token,
          orgToken: organizationId,
          refreshToken: refresh_token,
        };
      }

      case AuthActionTypes.Register: {
        const { authToken } = action.payload;

        return {
          ...state,
          authToken,
        };
      }

      case AuthActionTypes.UpdateToken: {
        let tokenState = { ...state, authToken: action.payload.authToken };
        if (action.payload.refreshToken)
          tokenState["refreshToken"] = action.payload.refreshToken;
        return tokenState;
      }

      case AuthActionTypes.ResetAuthReducer: {
        return initialAuthState;
      }
      case AuthActionTypes.UpdateDeviceInfo: {
        return { ...state, deviceInfo: action.payload.deviceInfo };
      }

      case AuthActionTypes.UserLoaded: {
        const {
          user: { account, idleTime, sessionId, validForSecs },
        } = action.payload;
        return {
          ...state,
          sessionId,
          account: {
            ...account,
            idleTime,
            validForSecs,
          },
        };
      }

      default:
        return state;
    }
  }
);
