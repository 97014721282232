export type BaseEntity = {
  id: string | number;
  version: string | number;
  active: boolean;
  tenantId: string;
};

export type AuditEntity = BaseEntity & {
  createdBy: string;
  createdDate: string | number | Date;
  lastModifiedBy: string;
  lastModifiedDate: string | number | Date;
};

export type BaseAccountModel = {
  accountId: string;
  login: string;
  personName: string;
};

export type Person = AuditEntity & {
  firstName: string;
  lastName: string;
  fullName: string;
  mobileNo: string;
  email: string;
  imageUrl: string;
  empId: string;
};

export class AccountsQuery {
  filter?: string = "";
  status?: string | string[];
  roleId?: string;
  pageNumber?: number = 0;
  pageSize?: number = 16;
  sortOrder?: string = "desc";
  sortField?: { id: string; value: string } = {
    id: "acc.createdDate",
    value: "Created Date",
  };
}

export type AuthEntity = {
  validForSecs: number;
  account: User;
  jwt_token: string;
  refreshToken: string;
};

export type UserStatusType = "New" | "Idle" | "Active" | "Locked" | "Disabled";
export type UserPasswordStatusType = "New" | "Valid" | "Expired";

export type User = BaseEntity &
  Person & {
    organizationId: string;
    accountId: string;
    login: string;
    password: string;
    activated: boolean;
    authorities: { authority: string }[];
    langKey: string;
    activationKey: string;
    resetKey: string;
    resetDate: string;
    sessionTimeout: number;
    roles: string[];
    status: { name: UserStatusType; colorHex: string };
    passwordStatus: UserPasswordStatusType;
    tntAdmin: boolean;
  };

export type Role = {
  appCode: string;
  appId: string;
  appName: string;
  roleId: string;
  roleName: string;
};

// Apps & Roles
export type AppPermissionModel = {
  name: string;
  code: string;
  appId: string;
  enabled: boolean;
  roles: AppRoleModel[];
  allowed: boolean;
};
export type AppRoleModel = {
  roleId: string;
  roleName: string;
  allowed: boolean;
  disable: boolean;
};
