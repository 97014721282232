import { AccountsQuery, User } from "./Account.model";
import { ActionTypes, FormType, AccountActionTypes } from "./accounts.types";

export const accountsRequested = (query: AccountsQuery): ActionTypes => ({
  type: AccountActionTypes.AccountsPageRequested,
  payload: { query },
});

export const accountsLoaded = ({
  accounts,
  totalCount,
  query,
}: {
  accounts: User[];
  totalCount: number;
  query: AccountsQuery;
}): ActionTypes => ({
  type: AccountActionTypes.AccountsPageLoaded,
  payload: { accounts, totalCount, query },
});

export const accountSelect = (
  accountId: string,
  onSuccessCB: (data) => void
): ActionTypes => ({
  type: AccountActionTypes.AccountSelection,
  payload: { accountId, onSuccessCB: onSuccessCB },
});

export const accountSelected = (account?: User): ActionTypes => ({
  type: AccountActionTypes.AccountSelected,
  payload: { account },
});

export const accountPageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: AccountActionTypes.AccountsPageToggleLoading,
  payload: { isLoading },
});

// export const accountAppsRolesRequested = (
//   accountId: string,
//   inclRoles: boolean
// ): ActionTypes => ({
//   type: AccountActionTypes.AccountAppsAndRolesRequested,
//   payload: { accountId, inclRoles },
// });
//
// export const accountAppsRolesLoaded = (apps: any): ActionTypes => ({
//   type: AccountActionTypes.AccountsAppsAndRolesLoaded,
//   payload: { apps },
// });

export const accountFormType = (type: FormType): ActionTypes => ({
  type: AccountActionTypes.AccountDetailFormType,
  payload: { type },
});

export const clearAccountsState = (): ActionTypes => ({
  type: AccountActionTypes.ClearAccountState,
  payload: {},
});
