import { Project, ProjectQuery } from "./project.model";
import { History } from "history";

export type FormType = "EDIT" | "READ_ONLY";

export type ProjViewType = "CARD" | "BOARD";

export interface ProjectState {
  listLoading: boolean;
  totalCount: number;
  lastQuery: ProjectQuery;
  formType: FormType;
  projViewType: ProjViewType;
  projects: Project[];
  selectedProject?: Project;
  overviewMenu: any;
}

export const ProjectActionTypes = {
  ProjectCreate: "[PROJECT] Create Project [API]",
  ProjectUpdate: "[PROJECT] Update Project [API]",
  ProjectDelete: "[PROJECT] Delete Project [API]",
  ProjectPageRequested: "[PROJECT] PageRequested [API]",
  ProjectViewType: "[PROJECT] Update ProjectView [ACTION]",
  ProjectDetailFormType: "[PROJECT] Detail Form Type [ACTION]",
  ProjectPageLoaded: "[PROJECT] Page Loaded [ACTION]",
  ProjectPageToggleLoading: "[PROJECT] Page Toggle Loading [ACTION]",
  ProjectSelection: "[PROJECT] Select Project [API]",
  ProjectSelected: "[PROJECT] Selected Project [ACTION]",
  ProjectOverviewMenuUpdate: "[PROJECT] Overview Menu Update [ACTION]",
  ClearProjectState: "[PROJECT] Clear Project State [ACTION]",
};

interface ProjectCreate {
  type: typeof ProjectActionTypes.ProjectCreate;
  payload: { account: Project };
}

interface ProjectPageRequested {
  type: typeof ProjectActionTypes.ProjectPageRequested;
  payload: { query: ProjectQuery };
}

interface ProjectPageLoaded {
  type: typeof ProjectActionTypes.ProjectPageLoaded;
  payload: { projects: Project[]; totalCount: number; query: ProjectQuery };
}

interface ProjectSelection {
  type: typeof ProjectActionTypes.ProjectSelection;
  payload: { projectId?: string; history?: History };
}

interface ProjectSelected {
  type: typeof ProjectActionTypes.ProjectSelected;
  payload: { project?: Project };
}

interface ProjectPageToggleLoading {
  type: typeof ProjectActionTypes.ProjectPageToggleLoading;
  payload: { isLoading: boolean };
}

interface ProjectDetailFormType {
  type: typeof ProjectActionTypes.ProjectDetailFormType;
  payload: { type: FormType };
}

interface UpdateProjectViewType {
  type: typeof ProjectActionTypes.ProjectViewType;
  payload: { type: ProjViewType };
}

interface ProjectOverviewMenuUpdate {
  type: typeof ProjectActionTypes.ProjectOverviewMenuUpdate;
  payload: { menu: any };
}

interface ClearProjectState {
  type: typeof ProjectActionTypes.ClearProjectState;
  payload: {};
}

export type ActionTypes =
  | ProjectCreate
  | ProjectPageRequested
  | ProjectPageToggleLoading
  | ProjectPageLoaded
  | ProjectSelection
  | ProjectSelected
  | UpdateProjectViewType
  | ProjectOverviewMenuUpdate
  | ProjectDetailFormType
  | ClearProjectState;
