import { removeEmptyProps } from "@biznessforce/ui-lib";
import { AxiosResponse } from "axios";
import { WFAxios } from "@api/api.instance";
import { ProjOverviewQuery } from "./proj_overview.model";

const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/proj/api`;

const constructParam = (payload: ProjOverviewQuery) => ({
  projName: payload.projName || "",
  fromDt: payload.fromDt,
  dur: payload.dur,
});

export const fetchProjOverview = (query: ProjOverviewQuery) => {
  const params: any = removeEmptyProps(constructParam(query));
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/project/overview`, { params });
};

export const fetchDayViewRoaster = ({
  dt,
  projName,
  projId,
  grpByShift,
}: any) => {
  const params: any = {
    ...removeEmptyProps({ projName, dt, projId }),
    grpbyShift: grpByShift,
  };
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/v2/dayview`, { params });
};
export const fetchWorkers = (params: any): Promise<AxiosResponse<any>> => {
  const paramsClone = { ...params };
  if (paramsClone.subType === "Assigned") paramsClone["subType"] = null;
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/roster/employees`, {
    params: removeEmptyProps(paramsClone),
  });
};
