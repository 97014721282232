import {
  ErrorBoundaries,
  EzDrawer,
  EzDrawerProvider,
  GlobalErrorHandler,
  NetworkDetector,
  SplashScreen,
} from "@biznessforce/ui-lib";

import { ConfigProvider, message } from "antd";
import React, { useEffect } from "react";
import { Provider, connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes/routes";
import { requestUser } from "./store/auth/auth.actions";
import { updateGlobalError } from "./store/config/config.actions";
import theme from "./theme/theme.module.scss";

export function App({
  store,
  persistor,
  basename,
  requestUser,
  globalError,
  ajaxLoader,
  updateGlobalError,
}) {
  const { isLoading } = ajaxLoader;
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: "loading",
        content: "Loading...",
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading, messageApi]);

  useEffect(() => {
    requestUser(); // Check if user logsIn & validate session else logout
  }, [requestUser]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        <React.Suspense fallback={<SplashScreen />}>
          <BrowserRouter basename={basename}>
            <ConfigProvider
              theme={{
                token: {
                  colorText: theme.primaryColor,
                  colorPrimary: theme.primaryColor,
                },
              }}
            >
              <NetworkDetector>
                <GlobalErrorHandler
                  globalError={globalError}
                  updateGlobalError={(v) => {
                    console.log(v);
                    updateGlobalError(v);
                  }}
                >
                  <ErrorBoundaries>
                    <EzDrawerProvider>
                      {contextHolder}
                      {/* Wrap the multiple children with a single parent element */}
                      <Routes />
                      <EzDrawer />
                    </EzDrawerProvider>
                  </ErrorBoundaries>
                </GlobalErrorHandler>
              </NetworkDetector>
            </ConfigProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

const mapStateToProps = ({
  config,
}: {
  config: {
    globalError: { type: string; msg: string };
    ajaxLoader: { isLoading: boolean; theme: string };
  };
}) => ({
  globalError: Object(config.globalError),
  ajaxLoader: Object(config.ajaxLoader),
});

const ConnectedApp = connect(mapStateToProps, {
  requestUser,
  updateGlobalError,
})(App);

export default ConnectedApp;
