import { constructErrorMessage } from "@biznessforce/ui-lib";
import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { DocumentQuery } from "./Document.model";
import {
  documentPageToggleLoading,
  documentsLoaded,
  documentsRequested,
} from "./documents.actions";
import { findDocuments } from "./documents.services";
import { ActionTypes, DocumentState } from "./documents.types";

export function* documentPageRequestedSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: DocumentQuery } };

  const { lastQuery }: { lastQuery: DocumentQuery } = yield select(
    (state: { documents: DocumentState }) => state.documents
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(documentPageToggleLoading(true));

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };

  try {
    const { data, headers } = yield findDocuments(finalQuery);

    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 1
    ) {
      yield put(documentsRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        documentsLoaded({
          query: finalQuery,
          documents: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    yield put(
      updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}
