import { ExclamationOutlined, FileTextFilled } from "@ant-design/icons";
import {
  COUNTRIES,
  getFlagEmoji,
  hasPermissions,
  removeEmptyProps,
} from "@biznessforce/ui-lib";
import { Alert } from "@store/overview/Overview.model";
import { Flex, Popover, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import React from "react";
import { SUPER_ADMIN } from "./permission";

export const hasAppPermissions = (
  permissions: string[],
  userRoles: string[]
) => {
  return hasPermissions(permissions, userRoles, SUPER_ADMIN);
};

export const findCountryFlag = (nationality: string) => {
  if (!nationality) return "";

  const code = COUNTRIES?.find(
    (country) => country?.nationality?.toUpperCase() === nationality
  )?.alpha_2_code as string;
  return getFlagEmoji(code);
};

export const checkQuery = (
  filterObject: any
): {
  hasQuery: boolean;
  queryCount: number;
  queryType: string[];
} => {
  const query = Object.fromEntries(
    Object.entries(filterObject).filter(([_, v]: any) => {
      return v && Array.isArray(v)
        ? v.length
        : typeof v === "object"
        ? v?.key !== "none" && v?.key
        : v;
    })
  );
  return {
    hasQuery: Boolean(Object.keys(removeEmptyProps(query)).length),
    queryCount: Object.keys(removeEmptyProps(query)).length,
    queryType: Object.keys(removeEmptyProps(query)), // returns query types present
  };
};

export const DocsAlertPopoverContent = ({ alerts }: { alerts: Alert[] }) => (
  <>
    {alerts.map((alert, idx) => (
      <Flex gap={"small"} align="center" key={idx}>
        <FileTextFilled style={{ color: alerts[0].level.colorHex }} />
        <Typography.Text>{alert.msg}</Typography.Text>
      </Flex>
    ))}
  </>
);

export const AlertPopover = ({
  content,
  title,
  placement,
  icon,
  colorHex,
}: {
  title: string;
  icon: React.ReactNode;
  content: React.ReactNode;
  colorHex?: string;
  placement?: TooltipPlacement;
}) => {
  const placement_ = placement || "right";

  if (!content) return null;

  return (
    <Popover
      content={content}
      placement={placement_}
      title={title}
      trigger="click"
      destroyTooltipOnHide
    >
      <Typography.Link style={{ color: colorHex || "#7e8299" }}>
        {icon || <ExclamationOutlined />}
      </Typography.Link>
    </Popover>
  );
};
