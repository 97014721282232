import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useIndexQuery } from "@hooks/useFetchOrgDetail";
import {
  login as loginAction,
  updateDeviceInfo,
} from "@store/auth/auth.actions";
import { fetchIpInfo, login } from "@store/auth/auth.service";
import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useQueryParams } from "@biznessforce/ui-lib";
import { FOOTER_PROPS } from "@constants/constants";
import useAPIHC from "@hooks/useAPIHC";
import dayjs from "dayjs";

function AuthPage() {
  const routerHistory = useHistory();
  const routerLocation = useLocation();

  const query = useQueryParams();
  const redirectQuery = query.get("redirect") || "";

  const dispatch = useDispatch();

  const [status, setStatus] = React.useState<any>(null);
  const [logout_msg, setLogoutMsg] = React.useState<any>(null);
  const { data: orgDetail } = useIndexQuery("orgDetail");

  const { onSubmit: loginDetails, loading } = useAPIHC(
    login,
    ({ resp: { data } }) => {
      dispatch(loginAction(data));
      const { from }: any = routerLocation.state || {
        from: { pathname: redirectQuery || "/" },
      };

      routerHistory.replace(from);
    },
    (err) => {
      console.log({ err });
      setStatus({ msg: err, type: "error" });
    }
  );

  useEffect(() => {
    const logoutMsg = localStorage.getItem("logout_msg");
    if (logoutMsg) {
      setLogoutMsg(logoutMsg);
    }
  }, []);

  const onFinish = async (values) => {
    let srcIp = "";
    const username = values.username;
    const password = values.password;

    try {
      const { data } = await fetchIpInfo();

      dispatch(updateDeviceInfo(data));

      srcIp = data?.ip;
    } catch (err) {
      console.log("Can't able to fetch info");
    }

    loginDetails({ srcIp, username, password });
  };

  return (
    <Row style={{ height: "100vh" }}>
      <Col xl={10} md={0}>
        <div
          style={{
            display: "block",
            height: "100%",
            position: "relative",
            backgroundImage: `url('/media/bg/home-bg.png')`,
          }}
        >
          <Flex
            align="center"
            style={{
              height: "100%",
              padding: "0px 50px",
            }}
          >
            <div style={{ marginBottom: "32px" }}>
              <div className="mb-5">
                {orgDetail?.logoUrl && (
                  <Image
                    src={`/${orgDetail?.logoUrl}`}
                    alt="ezforce"
                    preview={false}
                    style={{ maxWidth: "400px" }}
                  />
                )}

                <Typography.Title level={2}>
                  {orgDetail?.name || "Biznessforce"}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {orgDetail?.description ||
                    "Build with ❤️ by Biznessforce Software Solutions"}
                </Typography.Text>
              </div>
              {logout_msg && (
                <Typography.Text
                  className="position-absolute bottom-0 m-2"
                  type="secondary"
                >
                  {logout_msg}
                </Typography.Text>
              )}
            </div>
          </Flex>
        </div>
      </Col>
      <Col xl={14} md={24} sm={24} xs={24}>
        <Flex
          justify="center"
          align="center"
          style={{
            height: "100%",
            backgroundColor: "rgb(255, 255, 255)",
            padding: "96px 24px",
          }}
        >
          <div style={{ maxWidth: "320px" }}>
            <div style={{ marginBottom: "32px", textAlign: "center" }}>
              <img src={"/logo/logo_text_1.png"} alt="ezforce" />
              <div className="mt-4">
                <Typography.Text style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Welcome back! Please enter your details below to sign in.
                </Typography.Text>
              </div>
            </div>

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark={"optional"}
              size="large"
            >
              {status && (
                <Alert
                  message={status.msg as string}
                  type="error"
                  className="mb-3 text-danger"
                />
              )}

              <Form.Item
                name="username"
                label="Username"
                className="mb-0"
                rules={[
                  {
                    min: 3,
                    required: true,
                    message: "Please input your Username!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    min: 3,
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <div className="d-flex justify-content-end">
                  <Typography.Link className="login-form-forgot">
                    Forgot password
                  </Typography.Link>
                </div>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="w-100 login-form-button"
                loading={loading}
                disabled={loading}
              >
                Log in
              </Button>
            </Form>
          </div>
        </Flex>
        <Typography.Text
          className="position-absolute bottom-0 m-2"
          style={{ right: 0 }}
          type="secondary"
        >
          &copy; {dayjs().format("YYYY")} -{" "}
          <Typography.Link target="_blank" href="https://biznessforce.com">
            <strong>Biznessforce</strong>
          </Typography.Link>{" "}
          - v{FOOTER_PROPS.version} -{" "}
          {dayjs(FOOTER_PROPS.buildStamp).format("DD-MM-YYYY hh:mm A")}
        </Typography.Text>
      </Col>
    </Row>
  );
}

export default AuthPage;
