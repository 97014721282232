import { AccountActionTypes, AccountState } from "./accounts.types";
import { AccountsQuery } from "./Account.model";

const initialAccountState: AccountState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastQuery: new AccountsQuery(),
  formType: "READ_ONLY", // EDIT || READ_ONLY
  accounts: [],
  selectedAccount: undefined,
  appsAndRoles: [],
  showInitWaitingMessage: false,
  accountErrorMessage: undefined,
};

export const accountReducer = (
  state = initialAccountState,
  { type, payload }
): AccountState => {
  switch (type) {
    case AccountActionTypes.AccountsPageToggleLoading: {
      return {
        ...state,
        listLoading: payload.isLoading,
      };
    }
    case AccountActionTypes.AccountActionToggleLoading: {
      return {
        ...state,
        actionsLoading: payload.isLoading,
      };
    }
    case AccountActionTypes.AccountsPageLoaded: {
      return {
        ...state,
        accounts: payload.accounts,
        totalCount: payload.totalCount,
        listLoading: false,
        actionsLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
        showInitWaitingMessage: false,
      };
    }
    case AccountActionTypes.AccountDetailFormType: {
      return { ...state, formType: payload.type };
    }
    case AccountActionTypes.AccountErrorMsg: {
      return { ...state, accountErrorMessage: payload.errorMessage };
    }
    case AccountActionTypes.AccountSelected: {
      return { ...state, selectedAccount: payload.account };
    }
    case AccountActionTypes.AccountsAppsAndRolesLoaded: {
      return { ...state, appsAndRoles: payload.apps };
    }
    case AccountActionTypes.ClearAccountState: {
      state = initialAccountState;
      return { ...state };
    }
    default:
      return state;
  }
};
