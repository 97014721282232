import { ShowHideValue } from "./CellBody.model";
import { EmpQuery, OverviewQuery } from "./Overview.model";
import { OverviewActionTypes, OverviewState } from "./overview.types";

const initialOverviewState: OverviewState = {
  listLoading: false,
  totalCount: 0,
  lastQuery: new OverviewQuery(),
  overviews: undefined,
  empQuery: new EmpQuery(),
  empAvailabilities: {},
  empAssignLoading: false,
  projectAssignedDates: [],
  projectRemovedDates: [],
  attendaceShowHideValues: [
    ShowHideValue.InOutTime,
    ShowHideValue.TimeSplit,
    ShowHideValue.Leave,
    ShowHideValue.Skill,
  ],
};

export const overviewReducer = (
  state = initialOverviewState,
  { type, payload }
): OverviewState => {
  switch (type) {
    case OverviewActionTypes.OverviewPageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case OverviewActionTypes.OverviewPageLoaded:
      return {
        ...state,
        overviews: payload.overviews,
        listLoading: false,
        totalCount: payload.totalCount,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    case OverviewActionTypes.AssignEmpToProjLoading:
      return { ...state, empAssignLoading: payload.isLoading };
    case OverviewActionTypes.EmpAvblPageLoaded:
      return {
        ...state,
        empAvailabilities: payload.empAvailabilities,
        empQuery: payload.query,
      };
    case OverviewActionTypes.UpdatePrjAsgndDatesState:
      return { ...state, projectAssignedDates: payload.dates };
    case OverviewActionTypes.UpdatePrjRmvdDatesState:
      return { ...state, projectRemovedDates: payload.dates };
    case OverviewActionTypes.UpdateAttendanceShowHideState:
      return {
        ...state,
        attendaceShowHideValues: payload.showHideValues,
      };
    case OverviewActionTypes.ClearOverviewState: {
      state = initialOverviewState;
      return { ...state };
    }
    default:
      return state;
  }
};
