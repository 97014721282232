import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  FileZipOutlined,
  FolderOpenOutlined,
  HourglassOutlined,
  PartitionOutlined,
  PieChartOutlined,
  ProjectOutlined,
  SettingOutlined,
  StarOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  ATT_PERMISION,
  BILLING_PERMISSION,
  EMP_PERMISSION,
  PROJ_PERMISSION,
  TASK_PERMISSION,
  USER_ACC_ADMIN,
} from "./permission";

export const SidebarMenus = {
  dashboard: {
    key: "dashboard",
    label: "Dashboard",
    icon: <PieChartOutlined />,
    title: "Dashboard",
    permission: true,
  },
  empOverview: {
    key: "overview",
    label: "Employee Overview",
    icon: <AppstoreOutlined />,
    title: "Employee Overview",
    permission: [
      PROJ_PERMISSION.PROJ_ROSTER_MANAGE,
      PROJ_PERMISSION.PROJ_ROSTER_VIEW,
      ATT_PERMISION.ATTDTS_VIEW,
      ATT_PERMISION.ATTDTS_MANAGE,
    ],
  },
  projectOverview: {
    key: "project-overview",
    label: "Project Overview",
    icon: <ApartmentOutlined />,
    title: "Project Overview",
    permission: [
      PROJ_PERMISSION.PROJ_ROSTER_MANAGE,
      PROJ_PERMISSION.PROJ_ROSTER_VIEW,
    ],
    children: [
      {
        key: "daily-roster",
        label: "Daily Roster",
        title: "Daily Roster",
      },
      {
        key: "project-roster",
        label: "Overall Roster",
        title: "Overall Roster",
      },
    ],
  },
  projects: {
    key: "projects",
    label: "Projects",
    icon: <PartitionOutlined />,
    title: "Projects",
    permission: [PROJ_PERMISSION.PROJ_VIEW, PROJ_PERMISSION.PROJ_MANAGE],
  },
  employees: {
    key: "employees",
    label: "Employees",
    icon: <UsergroupAddOutlined />,
    title: "Employees",
    permission: [EMP_PERMISSION.EMP_VIEW, EMP_PERMISSION.EMP_MANAGE],
  },
  leaves: {
    key: "leaves",
    label: "Leaves",
    icon: <HourglassOutlined />,
    title: "Leaves",
    permission: [
      EMP_PERMISSION.EMP_LEAVE_VIEW,
      EMP_PERMISSION.EMP_LEAVE_MANAGE,
    ],
  },
  documents: {
    key: "documents",
    label: "Documents",
    icon: <FolderOpenOutlined />,
    title: "Documents",
    permission: [EMP_PERMISSION.EMP_DOC_VIEW, EMP_PERMISSION.EMP_DOC_MANAGE],
  },
  attendance: {
    key: "attendance",
    label: "Attendance & Timesheet",
    icon: <FieldTimeOutlined />,
    title: "Attendance & Timesheet",
    permission: false,
  },
  kanban: {
    key: "kanban",
    label: "Task Board",
    icon: <ProjectOutlined />,
    title: "Task Board",
    permission: [TASK_PERMISSION.TASK_USER, TASK_PERMISSION.TASK_MANAGE],
  },
  billing: {
    key: "billing",
    label: "Billing",
    icon: <FileProtectOutlined />,
    title: "Billing",
    permission: [...Object.values(BILLING_PERMISSION)],
  },
  customers: {
    key: "customers",
    label: "Customers",
    icon: <UserSwitchOutlined />,
    title: "Customers",
    permission: [
      PROJ_PERMISSION.PROJ_VIEW,
      PROJ_PERMISSION.PROJ_MANAGE,
      BILLING_PERMISSION.BILL_MANAGE,
    ],
  },
  invoice: {
    key: "invoice",
    label: "Invoice",
    icon: <AuditOutlined />,
    title: "Invoice",
    disabled: true,
    permission: [
      BILLING_PERMISSION.BILL_INVOICE_VIEW,
      BILLING_PERMISSION.BILL_INVOICE_MANAGE,
    ], //[BILLING_PERMISSION.BILL_INVOICE_VIEW, BILLING_PERMISSION.BILL_INVOICE_MANAGE],
  },
  rating: {
    key: "rating",
    label: "Rating",
    icon: <StarOutlined />,
    title: "Rating",
    permission: false,
  },
  payroll: {
    key: "payroll",
    label: "Payroll",
    icon: <StarOutlined />,
    title: "Payroll",
    permission: false,
  },
  reports: {
    key: "reports",
    label: "Reports",
    icon: <FileZipOutlined />,
    title: "Reports",
    permission: false,
  },
  accounts: {
    key: "accounts",
    label: "Accounts",
    icon: <UserAddOutlined />,
    title: "Accounts",
    permission: false,
  },
  preference: {
    key: "preference",
    label: "Preference",
    icon: <SettingOutlined />,
    title: "Preference",
    permission: [USER_ACC_ADMIN.USRACC_MANAGE],
  },
};
