export type Leave = {
  createdBy?: string;
  durDays?: number;
  empName?: string;
  employeeId?: string;
  fromDate?: number;
  fromDateStr?: string;
  fromDateType?: string;
  lastModifiedBy?: string;
  leaveId?: string;
  leaveType?: { name: string; colorHex: string };
  reason?: string;
  status?: { name: string; colorHex: string };
  toDate?: number;
  toDateStr?: string;
  toDateType?: string;
  createdDate?: number;
  createdDateStr?: string;
};

export class LeaveRecsQuery {
  empId?: string;
  filter?: string = "";
  pageNumber?: number = 0;
  fromDt?: string = "";
  toDt?: string = "";
  pageSize?: number = 25;
  sortOrder?: string = "desc";
  status?: string = "";
  leaveTypes?: string[] = [];
  sortField?: string = "lv.fromDate";
}
