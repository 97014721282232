import { Employee, EmployeeQuery } from "./Employee.model";

export type FormType = "EDIT" | "READ_ONLY";

export type AsideType = "NEW" | "EDIT" | "ACTIVITY" | "OVERVIEW_VIEW";
export type EmpViewType = "CARD" | "BOARD";

export interface EmployeeState {
  listLoading: boolean;
  totalCount: number;
  lastQuery: EmployeeQuery;
  asideType: AsideType;
  empViewType: EmpViewType;
  formType: FormType;
  employees: Employee[];
  selectedEmployee?: Employee;
  overviewMenu: any;
}

export const EmployeeActionTypes = {
  EmployeeCreate: "[EMPLOYEE] Create Employee [API]",
  EmployeeUpdate: "[EMPLOYEE] Update Employee [API]",
  EmployeeDelete: "[EMPLOYEE] Delete Employee [API]",
  EmployeePageRequested: "[EMPLOYEE] PageRequested [API]",
  AsideTypeUpdate: "[EMPLOYEE] UpdateAside [ACTION]",
  EmployeeDetailFormType: "[EMPLOYEE] Detail Form Type [ACTION]",
  EmployeePageLoaded: "[EMPLOYEE] Page Loaded [ACTION]",
  EmployeePageToggleLoading: "[EMPLOYEE] Page Toggle Loading [ACTION]",
  EmployeeSelection: "[EMPLOYEE] Select Employee [API]",
  EmployeeSelected: "[EMPLOYEE] Selected Employee [ACTION]",
  EmployeeOverviewMenuUpdate: "[EMPLOYEE] Overview Menu Update [ACTION]",
  ClearEmployeeState: "[Employee] Clear Employee State [ACTION]",
  EmpViewType: "[Employee] Update EmpView [ACTION]",
};

interface EmployeeCreate {
  type: typeof EmployeeActionTypes.EmployeeCreate;
  payload: { account: Employee };
}
// interface EmployeeUpdate {}
// interface EmployeeDelete {}
interface EmployeePageRequested {
  type: typeof EmployeeActionTypes.EmployeePageRequested;
  payload: { query: EmployeeQuery };
}
interface EmployeePageLoaded {
  type: typeof EmployeeActionTypes.EmployeePageLoaded;
  payload: { employees: Employee[]; totalCount: number; query: EmployeeQuery };
}
interface EmployeeSelection {
  type: typeof EmployeeActionTypes.EmployeeSelection;
  payload: { employeeId?: string; asideType?: AsideType };
}
interface EmployeeSelected {
  type: typeof EmployeeActionTypes.EmployeeSelected;
  payload: { employee?: Employee };
}

interface EmployeePageToggleLoading {
  type: typeof EmployeeActionTypes.EmployeePageToggleLoading;
  payload: { isLoading: boolean };
}
interface EmployeeDetailFormType {
  type: typeof EmployeeActionTypes.EmployeeDetailFormType;
  payload: { type: FormType };
}

interface AsideTypeUpdate {
  type: typeof EmployeeActionTypes.AsideTypeUpdate;
  payload: { type: AsideType; showOnTop?: boolean };
}
interface EmployeeOverviewMenuUpdate {
  type: typeof EmployeeActionTypes.EmployeeOverviewMenuUpdate;
  payload: { menu: any };
}

interface UpdateProjectViewType {
  type: typeof EmployeeActionTypes.EmpViewType;
  payload: { type: EmpViewType };
}

interface ClearEmployeeState {
  type: typeof EmployeeActionTypes.ClearEmployeeState;
  payload: {};
}

export type ActionTypes =
  | EmployeeCreate
  // | EmployeeUpdate
  // | EmployeeDelete
  | EmployeePageRequested
  | EmployeePageToggleLoading
  | EmployeePageLoaded
  | EmployeeSelection
  | EmployeeSelected
  | AsideTypeUpdate
  | UpdateProjectViewType
  | EmployeeOverviewMenuUpdate
  | EmployeeDetailFormType
  | ClearEmployeeState;
