import { takeLatest, takeLeading } from 'redux-saga/effects';
import * as sagaAction from './auth.saga';
import { AuthActionTypes } from './auth.types';

// Saga Functions
export function* authSaga() {
  yield takeLatest(AuthActionTypes.Login, sagaAction.loginSaga);

  yield takeLatest(AuthActionTypes.Register, sagaAction.registerSaga);

  yield takeLeading(AuthActionTypes.Logout, sagaAction.logoutSaga);

  yield takeLatest(AuthActionTypes.RefreshToken, sagaAction.refreshTokenSaga);

  yield takeLeading(
    AuthActionTypes.UserRequested,
    sagaAction.userRequestedSaga
  );
}
