import { takeLatest } from 'redux-saga/effects';
import { ProjOverviewActionTypes } from './proj_overview.types';
import { ProjOverviewRequestedSaga } from './proj_overview.saga';

export function* projOverviewSaga() {
  yield takeLatest(
    ProjOverviewActionTypes.ProjOverviewPageRequested,
    ProjOverviewRequestedSaga
  );
}
