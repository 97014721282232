import { Leave, LeaveRecsQuery } from "./leaves.model";

export interface LeaveState {
  leaveRecords: Leave[];
  listLoading: boolean;
  totalCount: number;
  lastQuery: LeaveRecsQuery;
}

export const LeaveActionTypes = {
  LeavePageRequested: "[LEAVE] PageRequested [API]",
  LeavePageToggleLoading: "[LEAVE] Page Toggle Loading [ACTION]",
  LeavePageLoaded: "[LEAVE] Page Loaded [ACTION]",
};

interface LeavePageRequested {
  type: typeof LeaveActionTypes.LeavePageRequested;
  payload: { query: LeaveRecsQuery };
}

interface LeavePageToggleLoading {
  type: typeof LeaveActionTypes.LeavePageToggleLoading;
  payload: { isLoading: boolean };
}

interface LeavePageLoaded {
  type: typeof LeaveActionTypes.LeavePageLoaded;
  payload: { leaveRecords: Leave[]; query: LeaveRecsQuery; totalCount: number };
}

export type ActionTypes =
  | LeavePageRequested
  | LeavePageToggleLoading
  | LeavePageLoaded;
