import { takeLatest } from "redux-saga/effects";
import {
  AssignEmpToProjSaga,
  EmpAvlPageRequestedSaga,
  OverviewPageRequestedSaga,
  SelectEmpAvailabilitiesSaga,
  UpdatePrjAsnDateSaga,
} from "./overview.saga";
import { OverviewActionTypes } from "./overview.types";

export function* overviewSaga() {
  yield takeLatest(
    OverviewActionTypes.OverviewPageRequested,
    OverviewPageRequestedSaga
  );
  yield takeLatest(
    OverviewActionTypes.EmpAvblPageRequested,
    EmpAvlPageRequestedSaga
  );
  yield takeLatest(
    OverviewActionTypes.UpdatePrjAsgndDate,
    UpdatePrjAsnDateSaga
  );
  yield takeLatest(OverviewActionTypes.AssingEmpToProj, AssignEmpToProjSaga);
  yield takeLatest(
    OverviewActionTypes.SelectEmpAvailabilies,
    SelectEmpAvailabilitiesSaga
  );
}
