import { removeEmptyProps } from "@biznessforce/ui-lib";
import { AxiosResponse, CancelToken } from "axios";
import { WFAxios } from "@api/api.instance";
import {
  EmpQuery,
  OverallEmployeeQuery,
  OverviewModel,
  OverviewQuery,
} from "./Overview.model";
import { TYPE_OF_OVERVIEW } from "./overview.types";

// {{wfproj-url-prefix}}/api/overview?size=30&sort=,DESC&fromDt=01-02-2021&dur=7&page=0&projName=Random&empName=Dallas
const WORKFORCE_PROJ_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/proj/api`;
const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/hr/api`;

const constructParam = (payload: OverviewQuery) => ({
  dur: payload.dur!.toString(),
  projName: payload.projName ? payload.projName?.toLowerCase() : null,
  empName: payload.empName ? payload.empName?.toLowerCase() : null,
  fromDt: payload.fromDt,
  toDt: payload?.toDt,
  idle: payload?.showIdle?.toString(),
  incharge: payload?.incharge,
  sort:
    payload.sortField!.id !== "none"
      ? `${payload.sortField!.id},${payload.sortOrder!.toUpperCase()}`
      : "",
  page: payload.pageNumber!.toString(),
  size: payload.pageSize!.toString(),
  siteId: payload.projIds?.length === 1 && payload.siteId ? payload.siteId : "",
  skillId: payload.skillIds?.length ? payload.skillIds.join(",") : null,
  projId: payload.projIds?.length ? payload.projIds.join(",") : null,
  leaveTypes: payload.leaveTypes?.length ? payload.leaveTypes.join(",") : null,
  partnerIds: payload.partnerId?.length ? payload.partnerId?.join(",") : null,
  orgzId: payload.orgId?.length ? payload.orgId?.join(",") : null,
  shiftIds: payload.shiftIds?.length ? payload.shiftIds.join(",") : null,
  pbcId: payload.pbcId,
  empId: payload.empIds,
});

const constructOverallEmpParam = (payload: OverallEmployeeQuery) => ({
  size: payload.pageSize?.toString(),
  page: payload.pageNumber?.toString(),
  srcDate: payload.srcDate,
  sort:
    payload.sortField && payload.sortField!.id !== "none"
      ? `${payload.sortField!.id},${payload.sortOrder!.toUpperCase()}`
      : "",
  searchText: payload.searchText ? payload.searchText?.toLowerCase() : null,
  dur: payload.dur?.toString(),
  fromDt: payload.fromDt,
  toDt: payload.toDt,
  projId: payload.projId,
  skillId: payload.skillId,
  ratingViewId: payload.ratingViewId,
  forProjId: payload.forProjId,
  forSiteId: payload.forSiteId,
  forShiftId: payload.forShiftId,
});

export const fetchOverview = (query: OverviewQuery, type: TYPE_OF_OVERVIEW) => {
  const params = removeEmptyProps(constructParam(query));
  return WFAxios.get(
    `${WORKFORCE_PROJ_BASE_URL}/v2/${type === "PROJECT" ? "overview" : "ats"}`,
    { params }
  );
};

export const fetchHolidaysAPI = (
  fromDt: string,
  toDt: string,
  cancelToken: CancelToken
) =>
  WFAxios.get(`${WORKFORCE_HR_BASE_URL}/holidays`, {
    params: { fromDt, toDt },
    cancelToken,
  });

export const getOverviewDetail = (
  empId: string,
  dt: string,
  forEdit?: boolean
) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/roster/cell/detail`, {
    params: { empId, dt, forEdit },
  });

export const uploadOverviews = (file: File): Promise<AxiosResponse> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(
    `${WORKFORCE_PROJ_BASE_URL}/project/assign/upload`,
    finalFile,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const updateCell = (cellData): Promise<AxiosResponse> =>
  WFAxios.put(`${WORKFORCE_PROJ_BASE_URL}/roster/cell/time`, cellData);

export const getEmpAvailability = (query: EmpQuery) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/emp/avblmatrix`, {
    params: { ...query, fromDt: query.fromDt, toDt: query.toDt },
  });

export const findOverallEmployee = (
  query: OverallEmployeeQuery
): Promise<AxiosResponse<OverviewModel[]>> =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/emp/find`, {
    params: removeEmptyProps(constructOverallEmpParam(query)),
  });

export const employeeBulkAssign = (payload) =>
  WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/project/assignmultple`, payload);

export const downloadTimeSheet = (query: OverviewQuery) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/report/timesheet/excel`, {
    params: removeEmptyProps(constructParam(query)),
    responseType: "blob",
  });

export const updateRosterService = (payload) =>
  WFAxios.put(
    `${import.meta.env.VITE_BASE_URL_WF}/proj/mobapi/v1/roster/cell`,
    removeEmptyProps(payload)
  );
