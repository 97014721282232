import { Customer, CustomerQuery } from "../model/Customer.model";

export type FormType = "EDIT" | "READ_ONLY";

export interface CustomerState {
  customers: Customer[];
  lastQuery: CustomerQuery;
  totalCount: number;
  listLoading: boolean;
  formType: FormType;
  selectedCustomer?: Customer;
  overviewMenu: any;
}

export const CustomerActionTypes = {
  CustomerPageRequested: "[CUSTOMER] PageRequested [API]",
  CustomerPageLoaded: "[CUSTOMER] Page Loaded [ACTION]",
  CustomerCreate: "[CUSTOMER] Create Customer [API]",
  CustomerUpdate: "[CUSTOMER] Update Customer [API]",
  CustomerDelete: "[CUSTOMER] Delete Customer [API]",
  CustomerPageToggleLoading: "[CUSTOMER] Page Toggle Loading [ACTION]",
  CustomerSelected: "[CUSTOMER] Selected Customer [ACTION]",
  CustomerSelection: "[CUSTOMER] Select Customer [API]",
  CustomerDetailFormType: "[CUSTOMER] Detail Form Type [ACTION]",
};

interface CustomerPageRequested {
  type: typeof CustomerActionTypes.CustomerPageRequested;
  payload: { query: CustomerQuery };
}

interface CustomerPageLoaded {
  type: typeof CustomerActionTypes.CustomerPageLoaded;
  payload: { customers: Customer[]; totalCount: number; query: CustomerQuery };
}

interface CustomerCreate {
  type: typeof CustomerActionTypes.CustomerCreate;
  payload: { customer: Customer };
}

interface CustomerPageToggleLoading {
  type: typeof CustomerActionTypes.CustomerPageToggleLoading;
  payload: { isLoading: boolean };
}

interface CustomerSelected {
  type: typeof CustomerActionTypes.CustomerSelected;
  payload: { customer?: Customer };
}

interface CustomerSelection {
  type: typeof CustomerActionTypes.CustomerSelection;
  payload: { partyId: string; onSuccessCB: (data: any) => void };
}

interface CustomerDetailFormtype {
  type: typeof CustomerActionTypes.CustomerDetailFormType;
  payload: { type: FormType };
}

export type ActionTypes =
  | CustomerCreate
  | CustomerPageRequested
  | CustomerPageLoaded
  | CustomerPageToggleLoading
  | CustomerSelected
  | CustomerSelection
  | CustomerDetailFormtype;
