import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { hotjar } from "react-hotjar";

/**
 * Provides the Heap script to inject.
 */
const getHeapScript = (id: number) => `
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
heap.load("${id}");
`;

const HEAP_SCRIPT_ID = "ezforce-heap";

const CLARITY_SCRIPT_ID = "ezforce-clarity";

const constructMicrosoftClarityScript = () => `
(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "dmjf1iwzqt");`;

export class Library {
  /**
   * Clarity is a free, easy-to-use tool that captures how real people actually use your site.
   *  Setup is easy and you'll start getting data in minutes.
   */
  initializeClarity = (): void => {
    if (!document) return;

    const hasScript = !!document.getElementById(CLARITY_SCRIPT_ID);

    if (hasScript) return;

    const script = document.createElement("script");
    script.innerHTML = constructMicrosoftClarityScript();
    script.id = CLARITY_SCRIPT_ID;
    script.async = true;

    document.body.appendChild(script);
  };

  /**
   * Sentry's application monitoring platform helps every developer diagnose, fix,
   * and optimize the performance of their code.
   * https://sentry.io/welcome/
   */
  initializeSentry = (): void => {
    Sentry.init({
      dsn: "https://eb24f47a0e2046cb83c3057940ec3938@o474998.ingest.sentry.io/6008266",

      environment: import.meta.env.VITE_ENV,

      integrations: [new Integrations.BrowserTracing()],
      autoSessionTracking: false,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  };
  /**
   * Heap is an analytics platform that helps product, marketing, and customer success teams craft exceptional digital experiences that convert and retain users.
   */
  initializeHeap = (): void => {
    if (!document) {
      return;
    }

    const hasScript = !!document.getElementById(HEAP_SCRIPT_ID);

    if (hasScript) {
      return;
    }

    const script = document.createElement("script");

    const devlHeapID = 3524240656;
    const prodHeapID = 206601106;

    script.innerHTML = getHeapScript(
      import.meta.env.VITE_ENV === "PRODUCTION" ? prodHeapID : devlHeapID
    );
    script.id = HEAP_SCRIPT_ID;
    script.async = true;

    document.body.appendChild(script);
  };

  // <!-- Hotjar Tracking Code for https://bzforce-react.surge.sh/ -->
  initializeHotjar = (): void => {
    const dvlHJID = 2707536;
    const prodHJID = 2707552;
    hotjar.initialize(
      import.meta.env.VITE_ENV === "PRODUCTION" ? prodHJID : dvlHJID,
      6
    );
  };
}
