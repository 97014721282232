import { ProjectActionTypes } from "./project.types";
import { projectSelectSaga, projectsPageRequestSaga } from "./project.saga";
import { takeLatest, takeLeading } from "redux-saga/effects";

export function* projectSaga() {
  yield takeLeading(
    ProjectActionTypes.ProjectPageRequested,
    projectsPageRequestSaga
  );

  yield takeLatest(ProjectActionTypes.ProjectSelection, projectSelectSaga);
}
