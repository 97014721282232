import { takeLeading } from "redux-saga/effects";
import { documentPageRequestedSaga } from "./documents.saga";
import { DocumentActionTypes } from "./documents.types";

export function* documentSaga() {
  yield takeLeading(
    DocumentActionTypes.DocumentPageRequested,
    documentPageRequestedSaga
  );
}
