import { DocumentQuery, Document } from "./Document.model";
import { ActionTypes, DocumentActionTypes } from "./documents.types";

export const documentsRequested = (query: DocumentQuery): ActionTypes => ({
  type: DocumentActionTypes.DocumentPageRequested,
  payload: { query },
});

export const documentPageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: DocumentActionTypes.DocumentPageToggleLoading,
  payload: { isLoading },
});

export const documentsLoaded = ({
  documents,
  totalCount,
  query,
}: {
  documents: Document[];
  totalCount: number;
  query: DocumentQuery;
}): ActionTypes => ({
  type: DocumentActionTypes.DocumentPageLoaded,
  payload: { documents, query, totalCount },
});
