import { put, retry, select } from "redux-saga/effects";
import * as actions from "./auth.actions";
import { getUserByToken, logout, refreshJwtToken } from "./auth.service";

import { clearAccountsState } from "../accounts/accounts.actions";
import { clearCoreConfigState } from "../config/config.actions";
import { clearEmployeeState } from "../employee/employee.actions";
import { clearOverviewState } from "../overview/overview.actions";
import { clearProjectstate } from "../project/project.actions";
import { clearProjOverviewState } from "../projectOverview/proj_overview.actions";

// All Other Modules Actions
// import { TAB_USER } from "../../../../apis/permissions";
// import { hasPermissions } from "../../core/helpers/PermissionHelper";

const getAuth = (state) => state.auth;

let refreshTokenInterval;

export function* loginSaga() {
  yield put(actions.requestUser());
}

export function* registerSaga() {
  yield put(actions.requestUser());
}

export function* logoutSaga(action) {
  const {
    payload: { timedOut },
  } = action;

  const { account, sessionId } = yield select(getAuth);

  yield clearInterval(refreshTokenInterval);
  yield put(actions.resetAuthReducer());
  yield put(clearAccountsState());
  yield put(clearEmployeeState());
  yield put(clearOverviewState());
  yield put(clearProjOverviewState());
  yield put(clearProjectstate());
  yield put(clearCoreConfigState());

  try {
    yield logout(account?.accountId, sessionId, timedOut);
  } catch (err) {
    /**
     * Need to handle error.
     */
  }
}

export function* refreshTokenSaga() {
  const { refreshToken, orgToken } = yield select(getAuth);

  try {
    const RETRY_TIMES = 7;
    const RETRY_SECOND = 5 * 1000;
    const { data } = yield retry(RETRY_TIMES, RETRY_SECOND, refreshJwtToken, {
      refreshToken: refreshToken,
      orgToken: orgToken,
    });
    yield put(
      actions.updateToken({
        authToken: data.jwt_token,
        refreshToken: data?.refresh_token,
      })
    );
  } catch (err) {
    yield put(actions.logout({ timedOut: false }));
  }
}

export function* userRequestedSaga() {
  const auth = yield select(getAuth);

  if (auth.authToken) {
    if (refreshTokenInterval) clearInterval(refreshTokenInterval);

    let { data: user } = yield getUserByToken();

    // Authorities data migration from arry[object] to array[String]
    const authorities = [];
    yield user["account"]["authorities"].forEach((el) => {
      authorities.push(el["authority"]);
    });
    yield (user["account"]["authorities"] = authorities);

    yield put(actions.fulfillUser(user));
  }
}
