import { takeLeading } from "redux-saga/effects";
import { leavesPageRequestedSaga } from "./leaves.saga";
import { LeaveActionTypes } from "./leaves.types";

export function* leavesSaga() {
  yield takeLeading(
    LeaveActionTypes.LeavePageRequested,
    leavesPageRequestedSaga
  );
}
