import { EmployeeState, EmployeeActionTypes } from "./employee.types";
import { EmployeeQuery } from "./Employee.model";

const initialEmployeeState: EmployeeState = {
  listLoading: false,
  totalCount: 0,
  lastQuery: new EmployeeQuery(),
  asideType: "NEW",
  formType: "READ_ONLY",
  empViewType: "BOARD",
  employees: [],
  selectedEmployee: undefined,
  overviewMenu: undefined,
};

export const employeeReducer = (
  state = initialEmployeeState,
  { type, payload }: any
): EmployeeState => {
  switch (type) {
    case EmployeeActionTypes.EmployeePageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case EmployeeActionTypes.EmployeePageLoaded: {
      return {
        ...state,
        employees: payload.employees,
        totalCount: payload.totalCount,
        listLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    }
    case EmployeeActionTypes.EmployeeSelected: {
      return { ...state, selectedEmployee: payload.employee };
    }
    case EmployeeActionTypes.AsideTypeUpdate: {
      return { ...state, asideType: payload.type };
    }
    case EmployeeActionTypes.EmployeeOverviewMenuUpdate: {
      return { ...state, overviewMenu: payload.menu };
    }
    case EmployeeActionTypes.EmpViewType: {
      return { ...state, empViewType: payload.type };
    }
    case EmployeeActionTypes.EmployeeDetailFormType: {
      return { ...state, formType: payload.type };
    }
    case EmployeeActionTypes.ClearEmployeeState: {
      state = initialEmployeeState;
      return { ...state };
    }
    default:
      return state;
  }
};
