export const AuthActionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  RefreshToken: "[Refresh Token] API",
  UpdateToken: "[Update Token] Action",
  ResetAuthReducer: "[Reset Auth] Action",
  SetIdleTime: "[Idle Time] Action",
  UpdateIdleTime: "[Idle State Update] Action",
  IdleStatus: "[Idle Status] Action",
  UpdateDeviceInfo: "[Update Device Info] Action",
};
