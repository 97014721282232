import {
  ActionTypes,
  FormType,
  AsideType,
  EmployeeActionTypes,
  EmpViewType,
} from "./employee.types";
import { Employee, EmployeeQuery } from "./Employee.model";

export const employeesRequested = (query: EmployeeQuery): ActionTypes => ({
  type: EmployeeActionTypes.EmployeePageRequested,
  payload: { query },
});

export const employeesLoaded = ({
  employees,
  totalCount,
  query,
}: {
  employees: Employee[];
  totalCount: number;
  query: EmployeeQuery;
}): ActionTypes => ({
  type: EmployeeActionTypes.EmployeePageLoaded,
  payload: { employees, totalCount, query },
});

export const employeeSelect = (
  employeeId: string,
  onSuccessCB: (data: any) => void
): ActionTypes => ({
  type: EmployeeActionTypes.EmployeeSelection,
  payload: { employeeId, onSuccessCB },
});

export const employeeSelected = (employee?: Employee): ActionTypes => ({
  type: EmployeeActionTypes.EmployeeSelected,
  payload: { employee },
});

export const employeePageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: EmployeeActionTypes.EmployeePageToggleLoading,
  payload: { isLoading },
});

export const employeeFormType = (type: FormType): ActionTypes => ({
  type: EmployeeActionTypes.EmployeeDetailFormType,
  payload: { type },
});

export const employeeAsideTypeUpdate = (type: AsideType): ActionTypes => ({
  type: EmployeeActionTypes.AsideTypeUpdate,
  payload: { type },
});

export const updateEmpViewType = (type: EmpViewType): ActionTypes => ({
  type: EmployeeActionTypes.EmpViewType,
  payload: { type },
});

export const clearEmployeeState = (): ActionTypes => ({
  type: EmployeeActionTypes.ClearEmployeeState,
  payload: {},
});
