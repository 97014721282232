import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import * as config from "./config/config.redux";

import { authReducer } from "./auth/auth.reducer";
import { authSaga } from "./auth/authRedux";

import { accountReducer } from "./accounts/accounts.reducer";
import { accountsSaga } from "./accounts/accountsRedux";

import { documentReducer } from "./documents/documents.reducer";

import { documentSaga } from "./documents/documents.redux";

import { overviewReducer } from "./overview/overview.reducer";
import { overviewSaga } from "./overview/overview.redux";

import { customerReducer } from "./customers/customer.reducer";
import { customerSaga } from "./customers/customer.redux";

import { projOverviewReducer } from "./projectOverview/proj_overview.reducer";
import { projOverviewSaga } from "./projectOverview/proj_overview.redux";

import { leaveReducer } from "./leaveRecords/leaves.reducer";
import { leavesSaga } from "./leaveRecords/leaves.redux";

import { employeeReducer } from "./employee/employee.reducer";
import { employeeSaga } from "./employee/employee.redux";

import { projectReducer } from "./project/project.reducer";
import { projectSaga } from "./project/project.redux";

import boardSliceReducer from "./task-management/kanban_board_slice";

export const appReducer = combineReducers({
  auth: authReducer,
  config: config.reducer,
  accounts: accountReducer,
  documents: documentReducer,
  overviews: overviewReducer,
  projects: projectReducer,
  customerConfig: customerReducer,
  projOverview: projOverviewReducer,
  leaves: leaveReducer,
  employees: employeeReducer,
  board: boardSliceReducer,
});

export const RootReducer = (state, action) => {
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    authSaga(),
    accountsSaga(),
    documentSaga(),
    overviewSaga(),
    projOverviewSaga(),
    customerSaga(),
    leavesSaga(),
    projectSaga(),
    employeeSaga(),
  ]);
}
