import { WFAxios } from "@api/api.instance";
import { removeEmptyProps } from "@biznessforce/ui-lib";
import { LeaveRecsQuery } from "./leaves.model";

const constructParam = (payload: LeaveRecsQuery) => ({
  searchText: payload.filter ? payload.filter.toLowerCase() : null,
  sort:
    payload.sortField !== "none"
      ? `${payload?.sortField},${payload?.sortOrder?.toUpperCase()}`
      : null,
  fromDt: payload.fromDt || null,
  toDt: payload.toDt || null,
  page: payload.pageNumber!.toString(),
  size: payload.pageSize!.toString(),
  leaveTypes: payload.leaveTypes?.length ? payload.leaveTypes.join(",") : null,

  // validity: payload.validity?.key !== "none" ? payload.validity?.key : null,
  status: payload.status || null,
  empId: payload.empId,
});

const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/hr/api`;

export const getLeaveRecords = (query: any) => {
  const params: any = removeEmptyProps(constructParam(query));
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/v2/emp/leaves`, {
    params: removeEmptyProps(params),
  });
};
