import { DocumentQuery } from "./Document.model";
import { DocumentActionTypes, DocumentState } from "./documents.types";

const initialDocumentState: DocumentState = {
  documents: [],
  listLoading: false,
  totalCount: 0,
  lastQuery: new DocumentQuery(),
};

export const documentReducer = (
  state = initialDocumentState,
  { type, payload }
): DocumentState => {
  switch (type) {
    case DocumentActionTypes.DocumentPageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case DocumentActionTypes.DocumentPageLoaded: {
      return {
        ...state,
        documents: payload.documents,
        totalCount: payload.totalCount,
        listLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    }
    default:
      return state;
  }
};
