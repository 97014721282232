import { SplashScreenProvider } from "@biznessforce/ui-lib";

import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";

import "@fortawesome/fontawesome-free/css/all.min.css";

import { Library } from "./libraries";
import store, { reactPersist } from "./store";

const PUBLIC_URL = import.meta.env.VITE_PUBLIC_URL;

const root = ReactDOM.createRoot(document.getElementById("root")!);

// Initializing Third party libraries
const library = new Library();

// if (import.meta.env.VITE_ENV === "production") {
console.log("Initializing Third party libraries");
library.initializeClarity();
library.initializeSentry();
// }

root.render(
  <StrictMode>
    <SplashScreenProvider>
      <App store={store} persistor={reactPersist} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </StrictMode>
);
