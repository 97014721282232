import { ProjectQuery } from "./project.model";
import { ProjectState, ProjectActionTypes } from "./project.types";

const initialProjectState: ProjectState = {
  listLoading: false,
  totalCount: 0,
  lastQuery: new ProjectQuery(),
  formType: "READ_ONLY",
  projects: [],
  projViewType: "CARD",
  selectedProject: undefined,
  overviewMenu: undefined,
};

export const projectReducer = (
  state = initialProjectState,
  { type, payload }: any
): ProjectState => {
  switch (type) {
    case ProjectActionTypes.ProjectPageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case ProjectActionTypes.ProjectPageLoaded: {
      return {
        ...state,
        projects: payload.projects,
        totalCount: payload.totalCount,
        listLoading: payload.isLoading,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    }
    case ProjectActionTypes.ProjectSelected: {
      return { ...state, selectedProject: payload.project };
    }
    case ProjectActionTypes.ProjectViewType: {
      return { ...state, projViewType: payload.type };
    }
    case ProjectActionTypes.ProjectOverviewMenuUpdate: {
      return { ...state, overviewMenu: payload.menu };
    }
    case ProjectActionTypes.ProjectDetailFormType: {
      return { ...state, formType: payload.type };
    }
    case ProjectActionTypes.ClearProjectState: {
      state = initialProjectState;
      return { ...state };
    }
    default:
      return state;
  }
};
