import { AccountActionTypes } from "./accounts.types";
import { accountsPageRequestSaga, accountSelectSaga } from "./accounts.saga";

import { takeLatest, takeLeading } from "redux-saga/effects";

export function* accountsSaga() {
  yield takeLeading(AccountActionTypes.AccountsPageRequested, accountsPageRequestSaga);

  yield takeLatest(AccountActionTypes.AccountSelection, accountSelectSaga);
}
