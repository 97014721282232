import { EmployeeActionTypes } from "./employee.types";
import {
  employeeSelectSaga,
  employeesPageRequestSaga,
} from "./employee.saga";
import { takeLatest, takeLeading } from "redux-saga/effects";

export function* employeeSaga() {
  yield takeLeading(EmployeeActionTypes.EmployeePageRequested, employeesPageRequestSaga);

  yield takeLatest(EmployeeActionTypes.EmployeeSelection, employeeSelectSaga);

}
