import {
  AssignProject,
  EmpQuery,
  OverviewModel,
  OverviewQuery,
} from "./Overview.model";
import {
  ActionTypes,
  OverviewActionTypes,
  TYPE_OF_OVERVIEW,
} from "./overview.types";

export const overviewRequested = (
  query: OverviewQuery,
  type: TYPE_OF_OVERVIEW
): ActionTypes => ({
  type: OverviewActionTypes.OverviewPageRequested,
  payload: { query, type },
});

export const overviewLoaded = ({
  overviews,
  query,
  totalCount,
}: {
  overviews: OverviewModel[][];
  query: OverviewQuery;
  totalCount: number;
}): ActionTypes => ({
  type: OverviewActionTypes.OverviewPageLoaded,
  payload: { overviews, query, totalCount },
});

export const overviewPageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: OverviewActionTypes.OverviewPageToggleLoading,
  payload: { isLoading },
});

export const empAvblRequested = (query: EmpQuery): ActionTypes => ({
  type: OverviewActionTypes.EmpAvblPageRequested,
  payload: { query },
});

export const empAvblLoaded = (
  availabilities: any,
  query: EmpQuery
): ActionTypes => ({
  type: OverviewActionTypes.EmpAvblPageLoaded,
  payload: { empAvailabilities: availabilities, query },
});

export const empAssignLoading = (isLoading: boolean): ActionTypes => ({
  type: OverviewActionTypes.AssignEmpToProjLoading,
  payload: { isLoading },
});

export const selectEmpAvailability = (
  type: "DAYS" | "SUN",
  checked: boolean
): ActionTypes => ({
  type: OverviewActionTypes.SelectEmpAvailabilies,
  payload: { type, checked },
});

export const updatePrjAsgndDate = (
  date: string,
  checked: boolean,
  isGreenCheckbox: boolean
): ActionTypes => ({
  type: OverviewActionTypes.UpdatePrjAsgndDate,
  payload: { date, checked, isGreenCheckbox },
});

export const updatePrjAsgndDatesState = (dates: string[]): ActionTypes => ({
  type: OverviewActionTypes.UpdatePrjAsgndDatesState,
  payload: { dates },
});

export const updatePrjRmvdDatesState = (dates: string[]): ActionTypes => ({
  type: OverviewActionTypes.UpdatePrjRmvdDatesState,
  payload: { dates },
});

export const assignEmpToProj = (assignPayload: AssignProject): ActionTypes => ({
  type: OverviewActionTypes.AssingEmpToProj,
  payload: { assignPayload },
});

export const clearOverviewState = (): ActionTypes => ({
  type: OverviewActionTypes.ClearOverviewState,
  payload: {},
});

export const updateAttendaceShowHideValues = (
  showHideValues: string[]
): ActionTypes => ({
  type: OverviewActionTypes.UpdateAttendanceShowHideState,
  payload: { showHideValues },
});
