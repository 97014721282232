import { Alert } from "../overview/Overview.model";

export type Employee = {
  alerts: Alert[];
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
  employeeId: string;
  fullName: string;
  mobileNo: string;
  status: { name: string; colorHex: string };
  statusMsg?: string;
  ppNo: string;
  whatsAppNo: string;
  idNo: string;
  idType: string;
  finNo: string;
  empNo: string;
  orgzAlias: string;
  orgzId: string;
  dobStr: string;
  gender: string;
  nationality: string;
  skills?: EmployeeSkill[];
  resrPartnerAlias?: string;
  resrPartnerName?: string;
  resrPartnerId?: string;
  skillCodes?: string[];
  passport?: EmployeeDoc;
  workpermist?: EmployeeDoc;
  fin?: EmployeeDoc;
  remarks: string;
  schedEnabled: string;
  profilePhoto?: string;
  profilePhotoSignedUrl?: string;
  accountId?: string;
  startDtStr?: string;
  endDtStr?: string;
  entity?: string;
  emailId?: string;
  joinDate?: string;
  wpNo: string;
};

export type EmployeeDoc = {
  docId: string;
  type: string;
  docNo: string;
  status: { name: string; colorHex: string };
  valStatus: { name: string; colorHex: string };
  issuedDateStr: string;
  validUntilStr: string;
  valNotReqd: string;
  alertLvl: string;
  remarks: string;
};

export type EmployeeSkill = {
  skillId: string;
  code: string;
  level: string;
  description: string;
  name: string;
  beginDate: string;
  beginDateStr: string;
  remarks: string;
  status: { name: string; colorHex: string };
};

export class EmployeeQuery {
  filter?: string = "";
  pageNumber?: number = 1;
  pageSize?: number = 24;
  sortOrder?: string = "asc";
  sortField?: string = "emp.fullName";
  skillIds?: string[] = [];
  status?: string;
  partnerId?: string[] = [];
  orgId?: string[] = [];
  projId?: string;
  resigned?: boolean;
  hasAcc?: boolean;
}

export type EmpRecentProjects = {
  projName: string;
  projFromDt: string;
  projToDt: string;
  regHr: string;
  ot1Hr: string;
  ot2Hr: string;
  lvSummaryDets: { leaveType: string; colorHex: string; days: number }[];
  noOfDaysPresent: number;
  attdPercent: number;
};
export type EmpLeaveRecords = {
  createdBy?: string;
  durDays?: number;
  empName?: string;
  employeeId?: string;
  fromDate?: number;
  fromDateStr?: string;
  fromDateType?: string;
  lastModifiedBy?: string;
  leaveId?: string;
  leaveType?: { name: string; colorHex: string };
  status?: { name: string; colorHex: string };
  colorHex?: string;
  name?: string;
  toDate?: number;
  toDateStr?: string;
  toDateType?: string;
  reason?: string;
  modifiedBy?: string;
};
