import { FormSectionHeader, FormVerColAlign } from "@biznessforce/ui-lib";
import useAPIHC from "@hooks/useAPIHC";
import { myCard } from "@store/task-management/kanban.service";
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React from "react";
import { priorityDropdownOptions } from "../../modules/TaskManagement/kanban_utils";

export const InstantCreateTaskForm = ({ handleCreate }) => {
  const [form] = Form.useForm();

  const { onSubmit: myCardAPI, loading: instantCardLoader } = useAPIHC(
    myCard,
    () => {
      form.resetFields();
      message.success("Card Created Successfully");
    },
    () => {},
    { showGlobalError: true, showGlobalLoader: false }
  );

  const handleFormSubmit = (values) => {
    if (!values?.title?.trim()) return;
    const payload = {
      ...values,
      title: values.title,
      priority: values.priority,
      dueDt: values.dueDt,
    };
    myCardAPI(payload);
  };

  return (
    <Flex vertical style={{ width: "20rem" }}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        // onValuesChange={handleFormValueChange}
        layout="vertical"
      >
        <FormSectionHeader
          title="Create Task"
          rightAction={
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              loading={instantCardLoader}
              disabled={instantCardLoader}
              onClick={handleCreate}
            >
              Create
            </Button>
          }
        />

        <Divider className="my-2" />

        <Row className="" gutter={[16, 16]}>
          <FormVerColAlign
            span={12}
            name="priority"
            label="Priority"
            formElement={
              <Select
                allowClear
                placeholder={"Priority"}
                options={priorityDropdownOptions.map(({ label, icon }) => ({
                  value: label,
                  label: (
                    <Space>
                      {icon}
                      {label}
                    </Space>
                  ),
                }))}
              />
            }
          />

          <FormVerColAlign
            span={12}
            name="dueDt"
            label="Due Date"
            formElement={<DatePicker format="DD-MM-YYYY" className="w-100" />}
          />

          {/* Form Title */}
          <FormVerColAlign
            span={24}
            name="title"
            label="Title"
            formElement={
              <Input.TextArea
                autoFocus
                rows={5}
                maxLength={150}
                style={{ resize: "none" }}
              />
            }
          />
        </Row>
      </Form>
    </Flex>
  );
};
