import { AxiosResponse } from "axios";
import { WFAxios } from "@api/api.instance";
import { DocumentQuery } from "./Document.model";
import { removeEmptyProps } from "@biznessforce/ui-lib";

const constructParam = (payload: DocumentQuery) => ({
  searchTerm: payload.filter ? payload.filter.toLowerCase() : null,
  sort:
    payload.sortField !== "none"
      ? `${payload.sortField},${payload.sortOrder!.toUpperCase()}`
      : null,
  page: payload.pageNumber!.toString(),
  size: payload.pageSize!.toString(),
  docType: Array.isArray(payload.docType)
    ? payload.docType.map((doc) => doc).join(",")
    : (payload.docType as any),
  validity: payload.validity !== "none" ? payload.validity : null,
  alertLvl: payload.alertLvl !== "none" ? payload.alertLvl : null,
});

const WORKFORCE_HR_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/hr/api`;
const WORKFORCE_PROJ_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/proj/api`;

export const findDocuments = (query) => {
  const params = removeEmptyProps(constructParam(query));
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/v2/employee/docs`, { params });
};

export const uploadEmpDocs = (file: File): Promise<AxiosResponse> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(
    `${WORKFORCE_PROJ_BASE_URL}/employees/docs/upload`,
    finalFile
  );
};

export const getDocumentExpirySummary = () => {
  return WFAxios.get(`${WORKFORCE_HR_BASE_URL}/employee/expdocssum`);
};
