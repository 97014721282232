import { ProjOverviewQuery, ProjOverview } from "./proj_overview.model";
import { ActionTypes, ProjOverviewActionTypes } from "./proj_overview.types";

export const projOverviewRequested = (
  query: ProjOverviewQuery
): ActionTypes => ({
  type: ProjOverviewActionTypes.ProjOverviewPageRequested,
  payload: { query },
});

export const projOverviewLoaded = ({
  dataList,
  query,
}: {
  dataList: ProjOverview;
  query: ProjOverviewQuery;
}): ActionTypes => ({
  type: ProjOverviewActionTypes.ProjOverviewPageLoaded,
  payload: { dataList, query },
});

export const projOverviewLoading = (isLoading: boolean): ActionTypes => ({
  type: ProjOverviewActionTypes.ProjOverviewPageToggleLoading,
  payload: { isLoading },
});

export const clearProjOverviewState = (): ActionTypes => ({
  type: ProjOverviewActionTypes.ClearProjOverviewState,
  payload: {},
});
