import { LeaveRecsQuery } from "./leaves.model";
import { LeaveActionTypes, LeaveState } from "./leaves.types";

const initialState: any = {
  leaveRecords: [],
  listLoading: false,
  totalCount: 0,
  lastQuery: new LeaveRecsQuery(),
};

export const leaveReducer = (
  state = initialState,
  { type, payload }: any
): LeaveState => {
  switch (type) {
    case LeaveActionTypes.LeavePageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case LeaveActionTypes.LeavePageLoaded: {
      return {
        ...state,
        leaveRecords: payload.leaveRecords,
        totalCount: payload.totalCount,
        listLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    }
    default:
      return state;
  }
};
