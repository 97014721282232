export class DocumentQuery {
  filter?: string = "";
  pageNumber?: number = 1;
  pageSize?: number = 25;
  sortOrder?: string = "asc";
  sortField?: string = "ed.validUntil";
  docType?: string[] = [];
  validity?: string;
  status?: string;
  alertLvl?: string;
}

export type Document = {
  alertLvl: string;
  employeeId: string;
  docId: string;
  type: string;
  docNo: string;
  issuedDate: number;
  issuedDateStr: string;
  validUntil: number;
  validUntilStr: string;
  valNotReqd: boolean;
  status: { name: string; colorHex: string };
  valStatus: { name: string; colorHex: string };
  remarks: string;
  employee: {
    idNo: string;
    fullName: string;
    orgzAlias: string;
  };
};
