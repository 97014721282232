import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constructErrorMessage } from "@biznessforce/ui-lib";
import { updateGlobalError } from "@store/config/config.actions";
import {
  fetchAllCardsByWidget,
  fetchAllCollections,
  fetchCollection,
} from "./kanban.service";

const initialState = {
  filter: {
    search: "",
    members: [],
    archived: false,
  },
  collections: [],
  selectedCollection: null,
  loading: false,
  error: null,
};

export const fetchCollections = createAsyncThunk(
  "board/fetchCollections",
  async (_, { dispatch }) => {
    try {
      const response = await fetchAllCollections();
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching collections:", error);
      dispatch(
        updateGlobalError({
          type: "error",
          message: constructErrorMessage(error.message),
        })
      );
      throw error;
    }
  }
);

// create a promise function to fetch all cards by widget
const fetchCardsAndConstructMapPromise = async (
  collectionDetail,
  collectionId,
  filter
) => {
  if (!collectionDetail) return [];

  const updatedWidgets = await Promise.all(
    collectionDetail?.widgets?.map(async (widget) => {
      const { data } = await fetchAllCardsByWidget({
        collectionId,
        widgetId: widget?.widgetId,
        ...filter,
      });
      const updatedWidgetColumns = widget?.columns?.map((column) => ({
        ...column,
        cards: data[column.columnId] || [],
      }));

      return { ...widget, columns: updatedWidgetColumns };
    })
  );

  return updatedWidgets;
};

export const fetchCollectionDetailById = createAsyncThunk(
  "board/fetchCollectionDetailById",
  async (collectionId, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await fetchCollection(collectionId);

      const {
        board: { filter },
      } = getState(); // <-- invoke and access state object

      const selectedCollection = { ...response.data };
      const updatedWidgets = await fetchCardsAndConstructMapPromise(
        selectedCollection,
        collectionId,
        filter
      );

      return { ...response.data, widgets: updatedWidgets };
    } catch (error) {
      console.error("Error fetching columns:", error);
      dispatch(
        updateGlobalError({
          type: "error",
          message: constructErrorMessage(error.message),
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    updateColumns: (state, action) => {
      const updatedColumns = action?.payload?.columns;
      const widgetId = action?.payload?.widgetId;

      const selectedWidget = state?.selectedCollection?.widgets?.find(
        (widget) => widget?.widgetId === widgetId
      );

      if (selectedWidget) {
        selectedWidget.columns = updatedColumns;
      }

      // replace the selected widget with the updated widget
      state.selectedCollection.widgets = state.selectedCollection.widgets.map(
        (widget) => {
          if (widget?.widgetId === widgetId) {
            return selectedWidget;
          }

          return widget;
        }
      );

      return state;
    },
    applyKanbanFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload?.filter };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.loading = false;
        state.collections = action.payload;
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCollectionDetailById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCollectionDetailById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedCollection = action.payload;
      })
      .addCase(fetchCollectionDetailById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  updateColumns: updateColumnsAction,
  applyKanbanFilter: applyKanbanFilterAction,
} = boardSlice.actions;

export default boardSlice.reducer;
