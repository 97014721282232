export const HOST_ADMIN = {
  HOST_ADMIN: "HA116518",
};

export const SUPER_ADMIN = "TNTSA81743290";

export const USER_ACC_ADMIN = {
  USRACC_VIEW: "UM634738",
  USRACC_MANAGE: "UM600038",
};

// export const DEMO_PERMISSIONS = {
//   DEMO_ONE: "DM124234",
//   DEMO_TWO: "DM145165",
//   DEMO_ALL_RO: "DM121885",
//   DEMO_ALL_ADMIN: "DM129995",
// };

export const EMP_PERMISSION = {
  EMP_VIEW: "HR771234",
  EMP_MANAGE: "HR771222",
  EMP_DOC_VIEW: "HR733322",
  EMP_DOC_MANAGE: "HR999222",
  EMP_LEAVE_VIEW: "HRL54312",
  EMP_LEAVE_MANAGE: "HRL44872",
};

export const PROJ_PERMISSION = {
  PROJ_VIEW: "PJ999934",
  PROJ_MANAGE: "PJ992291",
  PROJ_ROSTER_VIEW: "PJ123200",
  PROJ_ROSTER_MANAGE: "PJ666500",

  PROJ_CONF_RATE_VIEW: "PJCF120341",
  PROJ_CONF_RATE_MANAGE: "PJCF121451",
  PROJ_CONF_ATS_VIEW: "PJCF717121",
  PROJ_CONF_ATS_MANAGE: "PJCF881900",
};

export const TASK_PERMISSION = {
  TASK_USER: "TS145671",
  TASK_MANAGE: "TS225191",
};

export const BILLING_PERMISSION = {
  TIMESHEET_VERIFY: "BL111277",
  BILL_VIEW: "BL717934",
  BILL_MANAGE: "BL652891",
  BILL_INVOICE_VIEW: "BLI88643",
  BILL_INVOICE_MANAGE: "BLI61452",
};

export const ATT_PERMISION = {
  ATTDTS_VIEW: "ST787476",
  ATTDTS_MANAGE: "ST711333",
};
