import { Leave, LeaveRecsQuery } from "./leaves.model";
import { ActionTypes, LeaveActionTypes } from "./leaves.types";

export const leavesRequested = (query: LeaveRecsQuery): ActionTypes => ({
  type: LeaveActionTypes.LeavePageRequested,
  payload: { query },
});

export const leavePageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: LeaveActionTypes.LeavePageToggleLoading,
  payload: { isLoading },
});

export const leavePageLoaded = ({
  leaveRecords,
  totalCount,
  query,
}: {
  leaveRecords: Leave[];
  totalCount: number;
  query: LeaveRecsQuery;
}): ActionTypes => ({
  type: LeaveActionTypes.LeavePageLoaded,
  payload: { leaveRecords, query, totalCount },
});
