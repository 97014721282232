import { DocumentQuery, Document } from "./Document.model";

export interface DocumentState {
  listLoading: boolean;
  totalCount: number;
  lastQuery: DocumentQuery;
  documents: Document[];
}

export const DocumentActionTypes = {
  DocumentPageRequested: "[DOCUMENT] PageRequested [API]",
  DocumentPageToggleLoading: "[DOCUMENT] Page Toggle Loading [ACTION]",
  DocumentPageLoaded: "[DOCUMENT] Page Loaded [ACTION]",
};

interface DocumentPageRequested {
  type: typeof DocumentActionTypes.DocumentPageRequested;
  payload: { query: DocumentQuery };
}

interface DocumentPageToggleLoading {
  type: typeof DocumentActionTypes.DocumentPageToggleLoading;
  payload: { isLoading: boolean };
}

interface DocumentPageLoaded {
  type: typeof DocumentActionTypes.DocumentPageLoaded;
  payload: { documents: Document[]; query: DocumentQuery; totalCount: number };
}

export type ActionTypes =
  | DocumentPageRequested
  | DocumentPageToggleLoading
  | DocumentPageLoaded;
