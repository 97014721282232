import { WFAxios } from "@api/api.instance";
import { removeEmptyProps } from "@biznessforce/ui-lib";
import { Project, ProjectQuery, Site, TimeRule } from "./project.model";
import { AxiosResponse } from "axios";

const constructParams = (payload: ProjectQuery) => ({
  searchTerm: payload.filter ? payload.filter.toLowerCase() : null,
  sort:
    payload.sortField! !== "none"
      ? `${payload.sortField},${payload.sortOrder!.toUpperCase()}`
      : null,
  page: payload.pageNumber!.toString(),
  size: payload.pageSize!.toString(),
  orgzId:
    payload?.orgId && payload?.orgId?.length ? payload.orgId.join(",") : null,
  status: payload.status?.length ? payload.status.join(",") : null,
});

const WORKFORCE_PROJ_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/proj/api`;
const BILLING_BASE_URL: string = `${
  import.meta.env.VITE_BASE_URL_WF
}/billing/cfg/api`;

export const createProject = (project: any): Promise<AxiosResponse<Project>> =>
  WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/project`, project);

export const uploadProjects = (file: File): Promise<AxiosResponse<any>> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/project/upload`, finalFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadParties = (file: File): Promise<AxiosResponse<any>> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/parties/upload`, finalFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadAttendance = (
  projId: string,
  file: File
): Promise<AxiosResponse<any>> => {
  const finalFile = new FormData();
  finalFile.append("file", file);
  return WFAxios.post(
    `${WORKFORCE_PROJ_BASE_URL}/project/${projId}/ats/upload`,
    finalFile,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getProjectById = (
  projectId: string
): Promise<AxiosResponse<Project>> =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/${projectId}`);

export const findProjects = (
  query: ProjectQuery
): Promise<AxiosResponse<Project[]>> => {
  const params: any = removeEmptyProps(constructParams(query));

  return WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/v2/projects`, { params });
};

export const updateProject = (project: Project): Promise<AxiosResponse<any>> =>
  WFAxios.put(`${WORKFORCE_PROJ_BASE_URL}/project`, project);

export const getProjectsByMap = (): Promise<AxiosResponse<any>> =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/map`);

export const getSitesByProject = (
  projId: string
): Promise<AxiosResponse<Site[]>> =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/${projId}/sites`);

export const createSite = (payload: any): Promise<AxiosResponse<Site[]>> =>
  WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/project/site`, payload);

export const updateSite = (payload: any): Promise<AxiosResponse<Site[]>> =>
  WFAxios.put(`${WORKFORCE_PROJ_BASE_URL}/project/site`, payload);

export const deleteSite = (siteId: string): Promise<AxiosResponse<Site[]>> =>
  WFAxios.delete(`${WORKFORCE_PROJ_BASE_URL}/project/site/${siteId}`);

export const getProjectNames = (
  searchTerm: string,
  fromDt?: string,
  toDt?: string,
status? : string
) => {
  const params = { searchTerm, fromDt, toDt, status };
  return WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/names`, {
    params: removeEmptyProps(params),
  });
};

// export const getProjectFilters = () =>
//   WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/stats/count`);

export const assignProjToEmp = (payload: any) =>
  WFAxios.put(`${WORKFORCE_PROJ_BASE_URL}/project/assign`, payload);

export const removeProjFromEmp = (query: any) =>
  WFAxios.delete(`${WORKFORCE_PROJ_BASE_URL}/project/unassign`, {
    data: { ...query },
  });

export const removeProjValidate = (rosterDayId: string) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/unassign/validate`, {
    params: { rosterDayId },
  });

// Update Project Status
export const changeProjectStatus = (
  status: "begin" | "complete" | "close" | "cancel" | "confirm",
  projId: string,
  date?: string
) => {
  const params = {};
  if (date) params["dt"] = date;

  const finalUrl = `${WORKFORCE_PROJ_BASE_URL}/project/${status}/${projId}`;
  return WFAxios.put(finalUrl, {}, { params });
};

export const deleteProject = (projId: string) =>
  WFAxios.delete(`${WORKFORCE_PROJ_BASE_URL}/project/${projId}`);

// Shifts
export const getAllShifts = () =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/shifts`);

// ProjectIncharge
export const addProjectIncharge = (payload: {
  projectId: string;
  siteId: string;
  empId: string;
  fromDateStr: string;
  toDateStr: string;
  remarks: string;
}) => {
  const object = removeEmptyProps(payload);
  return WFAxios.post(
    `${WORKFORCE_PROJ_BASE_URL}/project/site/incharge`,
    object
  );
};

export const getProjectIncharge = (inchargeId: string) =>
  WFAxios.get(`${WORKFORCE_PROJ_BASE_URL}/project/site/incharge/${inchargeId}`);

export const updateProjectIncharge = (payload: {
  inchargeId: string;
  siteId: string;
  rosterId: string;
  formDateStr: string;
  toDateStr: string;
  remarks: string;
}) => {
  const object = removeEmptyProps(payload);
  return WFAxios.put(
    `${WORKFORCE_PROJ_BASE_URL}/project/site/incharge`,
    object
  );
};

export const removeProjectIncharge = (inchargeId: string) =>
  WFAxios.delete(
    `${WORKFORCE_PROJ_BASE_URL}/project/site/incharge/${inchargeId}`
  );

// Attendance Rule
export const getAttendanceRules = (projId: string) =>
  WFAxios.get(`${BILLING_BASE_URL}/atsrules?projId=${projId}`);

export const getAttendanceRule = (ruleId: string) =>
  WFAxios.get(`${BILLING_BASE_URL}/atsrule/${ruleId}`);

export const createAttendanceRule = (payload: any) =>
  WFAxios.post(`${BILLING_BASE_URL}/atsrule`, payload);

export const updateAttendanceRule = (payload: any) =>
  WFAxios.put(`${BILLING_BASE_URL}/atsrule`, payload);

export const activateAtsRule = (ruleId: string) =>
  WFAxios.put(`${BILLING_BASE_URL}/atsrule/${ruleId}/activate`);

export const getTimingRules = (projId: any) =>
  WFAxios.get(`${BILLING_BASE_URL}/atsrules?projId=${projId}`);

export const getDayRules = (projId: any) =>
  WFAxios.get(`${BILLING_BASE_URL}/dayrule/names?projId=${projId}`);

export const getHourRules = (projId: any) =>
  WFAxios.get(`${BILLING_BASE_URL}/hrrule/names?projId=${projId}`);

export const getSelectedTimeRule = (id: any) =>
  WFAxios.get(`${BILLING_BASE_URL}/atsrule/${id}`);

export const validateTimeRule = (payload: TimeRule) =>
  WFAxios.post(`${BILLING_BASE_URL}/rules/billingats/validate`, payload);

export const createTimeRule = (payload: TimeRule) =>
  WFAxios.post(`${BILLING_BASE_URL}/atsrule`, payload);

export const updateTimeRule = (payload: TimeRule) =>
  WFAxios.put(`${BILLING_BASE_URL}/atsrule`, payload);

// Hour Rules API
export const getAllHourRules = () =>
  WFAxios.get(`${BILLING_BASE_URL}/hrrules?inclDet=true`);
// Rate Rule API
export const getRateRules = ({
  projId,
  pbcId,
}: {
  projId?: string;
  pbcId?: string;
}) => {
  const params = { projId, pbcId };
  return WFAxios.get(`${BILLING_BASE_URL}/raterules`, {
    params: removeEmptyProps(params),
  });
};

export const getRateRule = (rateId: string, forEdit?: boolean) =>
  WFAxios.get(`${BILLING_BASE_URL}/raterule/${rateId}`, {
    params: { forEdit },
  });

export const createRateRule = (payload: any) =>
  WFAxios.post(`${BILLING_BASE_URL}/raterule`, payload);

export const UpdateRateRule = (payload: any) =>
  WFAxios.put(`${BILLING_BASE_URL}/raterule`, payload);

export const activateRateRule = (ruleId: string) =>
  WFAxios.put(`${BILLING_BASE_URL}/raterule/${ruleId}/activate`);

//No work
export const noWork = (payload: any, remove: boolean) =>
  WFAxios.post(`${WORKFORCE_PROJ_BASE_URL}/nowork`, payload, {
    params: removeEmptyProps({ remove }),
  });
