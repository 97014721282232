import axios from "axios";
import { WFAxios } from "@api/api.instance";
const version = "VERSION";

const AUTH_BASE_URL = `${import.meta.env.VITE_BASE_URL_ACCOUNT}/api`;

const LOGIN_URL = `${AUTH_BASE_URL}/authenticate`;
const REFRESH_TOKEN = `${AUTH_BASE_URL}/auth/renewtoken`;
const SELF_API = `${AUTH_BASE_URL}/account/self`;

const IPINFO_URL = `https://ipinfo.io/?token=${
  import.meta.env.VITE_IPINFO_TOKEN
}`;

export const appInfoHeaders = {
  "ezf-app-version": version,
  "ezf-app": "ezf-web",
};

export const login = ({ username, password, srcIp }) =>
  axios.post(
    LOGIN_URL,
    { username, password },
    {
      headers: {
        ...appInfoHeaders,
        SOURCE_IP: srcIp,
      },
    }
  );

export const fetchIpInfo = () => axios.get(IPINFO_URL);

export const logout = (accountId, sessionId, timedOut) =>
  axios.post(`${AUTH_BASE_URL}/logout`, { accountId, sessionId, timedOut });

export const getUserByToken = () => WFAxios.get(SELF_API);

export const refreshJwtToken = ({ refreshToken, orgId }) =>
  axios.post(
    REFRESH_TOKEN,
    {},
    {
      headers: {
        ...appInfoHeaders,
        Authorization: `Bearer ${refreshToken}`,
        organizationId: orgId,
        "Content-Type": "application/json",
      },
    }
  );
