import { CustomerQuery } from "./customer.model";
import { CustomerActionTypes, CustomerState } from "./customer.types";

const initialCustomerState: CustomerState = {
  formType: "READ_ONLY",
  customers: [],
  lastQuery: new CustomerQuery(),
  totalCount: 0,
  listLoading: false,
  selectedCustomer: undefined,
  overviewMenu: undefined,
};

export const customerReducer = (
  state = initialCustomerState,
  { type, payload }
): CustomerState => {
  switch (type) {
    case CustomerActionTypes.CustomerPageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case CustomerActionTypes.CustomerPageLoaded: {
      return {
        ...state,
        customers: payload.customers,
        totalCount: payload.totalCount,
        listLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    }
    case CustomerActionTypes.CustomerSelected: {
      return { ...state, selectedCustomer: payload.customer };
    }
    default:
      return state;
  }
};
