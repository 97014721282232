export const CoreConfigActionTypes = {
  ajaxLoader: "[ajaxLoader] Update ajaxLoader [ACTION]",
  globalAlert: "[globalAlert] Update global alert [ACTION]",
  updateGlobalError: "[GlobalError] Update global Error [ACTIOn]",
  clearCoreConfig: "[CLEAR CONFIG] Clear All State [ACTION]",
};

export const ajaxLoader = (ajaxLoader) => ({
  type: CoreConfigActionTypes.ajaxLoader,
  payload: { ajaxLoader },
});

export const updateGlobalError = (globalError) => ({
  type: CoreConfigActionTypes.updateGlobalError,
  payload: { globalError },
});

export const clearCoreConfigState = () => ({
  type: CoreConfigActionTypes.clearCoreConfig,
  payload: {},
});
