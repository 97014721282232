import { useAPI } from "@biznessforce/ui-lib";
import { ajaxLoader, updateGlobalError } from "@store/config/config.actions";
import { AxiosPromise, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";

export const useAPIHC = (
  APICall: (...props: any[]) => AxiosPromise,
  callback: ({
    resp,
    setStatus,
  }: {
    resp: AxiosResponse;
    setStatus: any;
  }) => void,
  errCallback?: (e: any) => void,
  config?: { showGlobalLoader: boolean; showGlobalError: boolean }
) => {
  const dispatch = useDispatch();

  return useAPI(APICall, callback, errCallback, {
    showGlobalError: config?.showGlobalError || false,
    showGlobalLoader: config?.showGlobalLoader || false,
    onLoader: ({ isLoading }) => {
      dispatch(ajaxLoader({ isLoading }));
    },
    onThrowError: (error) => {
      // console.log({ error });
      dispatch(updateGlobalError(error));
    },
  });
};

export default useAPIHC;
