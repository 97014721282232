export const FOOTER_PROPS = {
  buildStamp: __APP_BUILD_TIME__,
  version: `${__APP_VERSION__} - ${import.meta.env.MODE.toUpperCase()}`,
};

export type DATA_CONTAINER_TYPES =
  | "loading"
  | "error"
  | "nodata"
  | "select"
  | "dev";

export const NO_DATA_CONTAINER_MESSAGES = {
  NO_DATA: {
    key: "nodata",
    title: "No resuls found",
    description:
      "Try adjusting your search or filter to find what your'e looking for!",
  },
  SELECT: {
    key: "select",
    title: "Select a record",
    description: "Please select a record to view details",
  },
  LOADING: {
    key: "loading",
    title: "Loading...",
    description: "Please wait while we load the data",
  },
  DEV: {
    key: "dev",
    title: "We're doing some work on this page",
    description: "We're making improvements to this page. Check back soon!",
  },
  ERROR: {
    key: "error",
    title: "Something went wrong",
    description: "We're working to fix the problem as soon as we can.",
  },
};

// Avatar Cropper Constants
export const AVATAR_CROPPER_DEFAULT_IMAGE_PATH = "/media/users/blank.png";
export const ALLOWED_FILE_TYPES_MESSAGE =
  "Allowed file types are jpg, jpeg, png";
export const RESET_CROPPER_TOOLTIP = "Reset Cropper";
export const ZOOM_IN_TOOLTIP = "Zoom In";
export const ZOOM_OUT_TOOLTIP = "Zoom Out";

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
export const DATE_MONTH_FORMAT = "DD-MMM-YYYY (ddd)";
export const DATE_MONTH_DAY_FORMAT = "DD-MMM (ddd)";
export const DATE_TIME_MONTH_FORMAT = "DD-MMM-YYYY HH:mm";

export const INPUT_PASSWORD = "Please Input Your Password";
export const PASSWORD_MIN_CHARACTERS = "Password must be at least 7 characters";
export const PASSWORD_UPPERCASE =
  "Password must contain at least one uppercase letter";
export const PASSWORD_NUMERICS =
  "Password must contain at least one numeric digit";

export const DOCUMENT_TYPE_LIST = [
  { label: "Certificate", value: "Certificate" },
  { label: "CSOC", value: "CSOC" },
  { label: "OPSOC", value: "OPSOC" },
  { label: "Passport", value: "Passport" },
  { label: "Pass", value: "Pass" },
  //   { label: "FIN", value: "FIN" },
  { label: "WorkPermit", value: "WorkPermit" },
  { label: "Other", value: "Other" },
];

export const DOCUMENT_VALIDITY_LIST = [
  { value: "NotSet", label: "Not Specified" },
  { value: "NotReqd", label: "Not Required" },
  { value: "Expired", label: "Expired" },
  { value: "ExpIn30days", label: "Expires in 30 days" },
  { value: "ExpIn30to60days", label: "Expires in 30-60 days" },
  { value: "ExpAfter60days", label: "Expires after 60 days" },
];

export const DOCUMENT_ALERT_TYPE_LIST = [
  { value: "None", label: "None" },
  { value: "Info", label: "Info" },
  { value: "Warn", label: "Warning" },
  { value: "Escalate", label: "Escalate" },
];

export const DOCUMENT_SORT_BY_LIST = [
  { value: "none", label: "- select -" },
  { value: "emp.fullName", label: "Employee Name" },
  { value: "ed.docType", label: "Doc Type" },
  { value: "ed.issuedDate", label: "Doc Issued Date" },
  { value: "ed.validUntil", label: "Doc Valid Until" },
  { value: "ed.status", label: "Doc Status" },
];

export const EMPLOYEE_SORT_BY_LIST = [
  { value: "none", label: "- select -" },
  { value: "emp.fullName", label: "Emp. Name" },
  { value: "emp.nationality", label: "Nationality" },
  { value: "emp.createdDate", label: "Crtd. Date" },
  { value: "emp.status", label: "Status" },
];
export const CUSTOMERS_SORT_BY_LIST = [
  { value: "p.name", label: "Customer Name" },
  { value: "p.createdDate", label: "Created Date" },
];

export const constructRoleConfirmationMessage = (type) => {
  return `Are you sure you want to ${type} role `;
};

// Overview Cell Constants
export const CELL_7_DAYS_VIEW = "7_DAYS-VIEW";
export const CELL_15_DAYS_VIEW = "7_DAYS-VIEW";
export const OVERVIEW_COLUMN_KEY_TYPE = "COL";
export const OVERVIEW_DATE_KEY_TYPE = "DATE";
export const OVERVIEW_CELL_KEY_TYPE = "CELL";

export type OVERVIEW_CELL_MODULE_TYPES = {
  "7_DAYS_VIEW": "7_DAYS_VIEW";
  "15_DAYS_VIEW": "15_DAYS_VIEW";
  COLUMN_KEY_TYPE: "COL";
  DATE_KEY_TYPE: "DATE";
  CELL_KEY_TYPE: "CELL";
};

export const CONSTRANCT_CRITERIA_MODEL = {
  Skill: "Skill",
  Shift: "Shift",
  ProjSite: "Project Site",
  Worker: "Employees",
  WorkerNat: "Nationality",
};

export const CONTRACT_CRITERA_LIST = [
  "ProjSite",
  "Shift",
  "Skill",
  "Worker",
  "WorkerNat",
];
