import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { CustomerQuery } from "./customer.model";
import {
  customerLoaded,
  customerPageToggleLoading,
  customerRequested,
  customerSelected,
} from "./customer.actions";
import { findCustomers, getCustomerByName } from "./customer.service";
import { ActionTypes, CustomerState } from "./customer.types";
import { constructErrorMessage } from "@biznessforce/ui-lib";

export function* customerPageRequestedSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: CustomerQuery } };

  const { lastQuery }: { lastQuery: CustomerQuery } = yield select(
    (state: { customerConfig: CustomerState }) => state.customerConfig
  );
  yield put(ajaxLoader({ isLoading: true }));
  yield put(customerPageToggleLoading(true));

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };

  try {
    const { data, headers } = yield findCustomers(finalQuery);

    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 1
    ) {
      yield put(customerRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        customerLoaded({
          query: finalQuery,
          customers: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    yield put(
      updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}

export function* customerSelectSaga(action: ActionTypes) {
  const {
    payload: { partyId, onSuccessCB },
  } = action as {
    payload: { partyId: string; onSuccessCB: (data) => void };
  };
  if (partyId) {
    try {
      const { data } = yield getCustomerByName(partyId);
      yield put(customerSelected(data));
      yield put(updateGlobalError(undefined));
      onSuccessCB(data);
    } catch (error) {
      yield put(
        updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
      );
    }
  }
}
