import { removeEmptyProps } from "@biznessforce/ui-lib";
import { WFAxios } from "@api/api.instance";

const TASK_BASE_URL = `${import.meta.env.VITE_BASE_URL_TASK}/task/api`;

// Collections
export const fetchAllCollections = () => {
  return WFAxios.get(`${TASK_BASE_URL}/collections`);
};

export const fetchCollection = (id: string) => {
  return WFAxios.get(`${TASK_BASE_URL}/collection?id=${id}`);
};

export const createCollection = (payload: any) => {
  return WFAxios.post(`${TASK_BASE_URL}/collection`, payload);
};

export const updateCollection = (collectionId: string, payload: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/collection`, payload, {
    params: { collectionId },
  });
};

export const deleteCollection = (collectionId: string) => {
  return WFAxios.delete(`${TASK_BASE_URL}/collection`, {
    params: { collectionId },
  });
};

export const archiveCollection = (collectionId: string, revert: boolean) => {
  return WFAxios.put(
    `${TASK_BASE_URL}/collection/archive`,
    {},
    {
      params: removeEmptyProps({ collectionId, revert }),
    }
  );
};
// Columns
export const createColumn = (colId: string, widgetId: string, payload: any) => {
  return WFAxios.post(`${TASK_BASE_URL}/collection/column`, payload, {
    params: { colId, widgetId },
  });
};

export const updateColumn = (colId: string, widgetId: string, payload: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/collection/column`, payload, {
    params: { colId, widgetId },
  });
};

export const deleteColumn = (
  colId: string,
  widgetId: string,
  columnId: string
) => {
  return WFAxios.delete(`${TASK_BASE_URL}/collection/column`, {
    params: { colId, widgetId, columnId },
  });
};

export const archiveAllCards = (
  collectionId: string,
  widgetId: string,
  columnId: string
) => {
  return WFAxios.put(
    `${TASK_BASE_URL}/card/archiveall`,
    {},
    {
      params: { collectionId, widgetId, columnId },
    }
  );
};

// Cards
export const fetchAllCardsByWidget = (filter: any) => {
  if (filter?.members.length > 0) {
    filter.assignToAccId = filter.members?.join(",");
    delete filter.members;
  } else {
    delete filter.members;
  }

  if (filter?.search) {
    filter.searchTxt = filter.search.trim();
    delete filter.search;
  }

  const params = removeEmptyProps(filter);

  return WFAxios.get(`${TASK_BASE_URL}/cardsmap`, { params });
};

export const fetchCard = (id: string) => {
  return WFAxios.get(`${TASK_BASE_URL}/card?cardId=${id}`);
};

export const createCard = (payload: any) => {
  return WFAxios.post(`${TASK_BASE_URL}/card`, payload);
};

export const updateCard = (id: string, data: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/card`, data);
};

export const deleteCard = (cardId: string) => {
  return WFAxios.delete(`${TASK_BASE_URL}/card`, { params: { cardId } });
};

export const transferCard = (payload: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/card/statuschange`, payload);
};

export const assignCard = (data: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/card/assignto`, data);
};

export const unassignCard = (data: any) => {
  return WFAxios.put(`${TASK_BASE_URL}/card/assignto`, data);
};

export const archiveCard = (cardId: string, revert: boolean) => {
  return WFAxios.put(
    `${TASK_BASE_URL}/card/archive`,
    {},
    { params: removeEmptyProps({ cardId, revert }) }
  );
};

// Comments
export const createComment = (payload: any) => {
  return WFAxios.post(`${TASK_BASE_URL}/comment`, payload);
};

export const getAllComments = (cardId: string) => {
  return WFAxios.get(`${TASK_BASE_URL}/comments`, { params: { cardId } });
};

export const deleteComment = (cardId: string, commentId: string) => {
  return WFAxios.delete(`${TASK_BASE_URL}/comment`, {
    params: { cardId, commentId },
  });
};

//mark as completed

export const markAsComplete = (cardId: string, revert: boolean) => {
  return WFAxios.put(
    `${TASK_BASE_URL}/card/complete`,
    {},
    { params: removeEmptyProps({ cardId, revert }) }
  );
};

export const getAllMyCards = (searchTxt: string, archived: boolean) => {
  return WFAxios.get(`${TASK_BASE_URL}/mycards`, {
    params: removeEmptyProps({ searchTxt, archived }),
  });
};

export const myCard = (payload: any) => {
  return WFAxios.post(`${TASK_BASE_URL}/card`, payload);
};
