import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { RootReducer, rootSaga } from "./root-reducer";

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware: any = (getDefaultMiddleware: any) => [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: RootReducer,
  middleware,
  devTools: import.meta.env.VITE_ENV !== "production",
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers({
      autoBatch: { type: "tick" },
    }).concat(reduxBatch);
  },
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const reactPersist = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
