import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { AccountsQuery } from "./Account.model";
import {
  accountPageToggleLoading,
  accountSelected,
  accountsLoaded,
  accountsRequested,
} from "./accounts.actions";
import { findAccounts, getAccountById } from "./accounts.service";
import { AccountState, ActionTypes } from "./accounts.types";
import { constructErrorMessage } from "@biznessforce/ui-lib";

export function* accountsPageRequestSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: AccountsQuery } };

  const { lastQuery }: { lastQuery: AccountsQuery } = yield select(
    (state: { accounts: AccountState }) => state.accounts
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(accountPageToggleLoading(true));

  const finalQuery = { ...lastQuery, ...query };

  try {
    const { data, headers } = yield findAccounts(finalQuery);

    // If page has 0 data should navigate to previous page
    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 0
    ) {
      yield put(accountsRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        accountsLoaded({
          query,
          accounts: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    /**
     * Need to handle error.
     */
    yield put(
      updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}

export function* accountSelectSaga(action: ActionTypes) {
  const {
    payload: { accountId, onSuccessCB },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = action as any;
  yield put(ajaxLoader({ isLoading: true }));
  if (accountId) {
    try {
      const { data } = yield getAccountById(accountId);
      yield put(accountSelected(data));
      yield put(updateGlobalError(undefined));
      onSuccessCB(data);
      yield put(ajaxLoader({ isLoading: false }));
    } catch (error) {
      yield put(
        updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
      );
      yield put(ajaxLoader({ isLoading: false }));
    }
  }
}
