import {
  EzIdleTimer,
  Layout,
  UserDropdownProvider,
  useEzDrawer,
} from "@biznessforce/ui-lib";
import { SidebarMenus } from "@constants/SidebarMenus";
import { SUPER_ADMIN } from "@constants/permission";
import useConfigureOrgDetail from "@hooks/useFetchOrgDetail";
import { changePassword } from "@store/accounts/accounts.service";
import { logout as logoutAction } from "@store/auth/auth.actions";
import React, { lazy } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Logout from "../modules/Auth/Logout";
import MainRoutes from "./main.routes";
import { AuthenticatedRoute, RouterSwitch } from "./utils.routes";
import AuthPage from "../modules/Auth/AuthPage";
import { FloatButton, Popover } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { InstantCreateTaskForm } from "../core/components/instantCreateTaskForm";

const PublicModuleLazy = lazy(() => import("./public.routes"));

const ComponentWrapper = () => {
  const [showInstantForm, setShowInstantForm] = React.useState();

  const { account, isAuthorized } = useSelector(
    ({ auth: { account, authToken } }) => ({
      account,
      isAuthorized: authToken != null,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { closeDrawer } = useEzDrawer();

  return (
    <>
      <Layout
        children={
          <UserDropdownProvider
            account={account}
            changePasswordAPI={changePassword}
            children={
              <>
                <MainRoutes />
                <Popover
                  open={showInstantForm}
                  content={<InstantCreateTaskForm />}
                  placement="leftTop"
                >
                  <FloatButton
                    trigger="click"
                    icon={
                      showInstantForm ? (
                        <CloseCircleOutlined />
                      ) : (
                        <PlusCircleOutlined />
                      )
                    }
                    onClick={() => setShowInstantForm(!showInstantForm)}
                    shape="square"
                    type="primary"
                  ></FloatButton>
                </Popover>
              </>
            }
          />
        }
        sidebarProps={{
          logo: "/logo/ezforce-32x32.png",
          menus: SidebarMenus,
          authorities: account?.authorities,
          SUPER_ADMIN: SUPER_ADMIN,
        }}
      />

      <EzIdleTimer
        defaultTimeout={account?.sessionTimeout}
        enabled={isAuthorized}
        onLogout={() => {
          closeDrawer();
          localStorage.setItem("logout_msg", "Session timed out");
          dispatch(logoutAction({ timedOut: true }));
        }}
      />
    </>
  );
};

function Routes() {
  // Fetching Organisation Detail
  useConfigureOrgDetail();
  return (
    <RouterSwitch>
      <Route path="/dacs" component={PublicModuleLazy} />
      <Route path="/logout" component={Logout} />
      <Route path="/auth/login" component={AuthPage} />
      <AuthenticatedRoute component={ComponentWrapper} />
    </RouterSwitch>
  );
}

export default Routes;
