import React, { useEffect, useState } from "react";
import { getOrganisationDetail } from "@store/accounts/accounts.service";
import { getStorage, setStorage } from "../database";
import useAPIHC from "@hooks/useAPIHC";

const getHostName = () => {
  let hostname = window.location.hostname.split(".")[0];
  if (hostname === "localhost") hostname = "stagv2";
  return hostname;
};

export const useIndexQuery = (query) => {
  const [data, setData] = useState(null);

  const fetchData = async (query) => {
    try {
      const value = await getStorage(query);
      setData(value);
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    fetchData(query);
  }, [query]);

  return { data };
};

function useConfigureOrgDetail() {
  const { onSubmit: fetchOrgDetail, loading } = useAPIHC(
    getOrganisationDetail,
    ({ resp: { data } }) => {
      setStorage("orgDetail", { ...data, hostName: getHostName() });
    },
    () => {}
  );

  React.useEffect(() => {
    const host = getHostName();
    fetchOrgDetail(host);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading };
}

export default useConfigureOrgDetail;
