import axios from "axios";
import { requestFailure, requestSuccess } from "./api.utils";

const BASE_URL = `${import.meta.env.VITE_BASE_URL_ACCOUNT}/accservice`;

// Accounts Instance
export const WFAxios = axios.create({ baseURL: BASE_URL });

WFAxios.interceptors.request.use(
  (config) => requestSuccess(config),
  requestFailure
);
WFAxios.interceptors.response.use((config) => config, requestFailure);

export default WFAxios;
