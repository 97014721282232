export class ProjOverviewBase {
  cellDt?: number;
  cellDtStr?: string;
  required?: number;
  orgzAlias?: string;
  assigned?: number;
  projId?: string;
  projName?: string;
  status?: { name: string; colorHex: string };
  customerId?: string;
  customerName?: string;
  beginDate?: string;
  complDate?: string;
  notAvbleReason?: string;
  notAvble?: boolean;
}

class SummaryBase extends ProjOverviewBase {
  details?: ProjOverviewBase[];
}

export class ProjOverview {
  idle?: SummaryBase;
  leave?: SummaryBase;
  projects: SummaryBase[] = [];
}

export class ProjOverviewQuery {
  fromDt?: string;
  dur?: number = 15;
  projName?: string = "";
  projIds?: { value: string; key: string }[] = [];
  skillIds?: { value: string; key: string }[] = [];
}

export type WorkerModel = {
  empId: string;
  empIdNo: string;
  empName: string;
  orgzAlias: string;
  empProfilePhotoKey?: string;
  empProfilePhotoUrl?: string;
  skillLevel: number;
  skillCode: string;
  skillName: string;
  timeInStr: string;
  timeOutStr: string;
};
