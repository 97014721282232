import { History } from "history";
import {
  ActionTypes,
  FormType,
  ProjViewType,
  ProjectActionTypes,
} from "./project.types";
import { Project, ProjectQuery } from "./project.model";

export const projectsRequested = (query: ProjectQuery) => ({
  type: ProjectActionTypes.ProjectPageRequested,
  payload: { query },
});

export const projectsLoaded = ({
  projects,
  totalCount,
  query,
}: {
  projects: Project[];
  totalCount: number;
  query: ProjectQuery;
}) => ({
  type: ProjectActionTypes.ProjectPageLoaded,
  payload: { projects, totalCount, query },
});

export const projectSelect = (projectId?: string, history?: History) => ({
  type: ProjectActionTypes.ProjectSelection,
  payload: { projectId, history },
});

export const projectSelected = (project?: Project) => ({
  type: ProjectActionTypes.ProjectSelected,
  payload: { project },
});

export const projectPageToggleLoading = (isLoading: boolean) => ({
  type: ProjectActionTypes.ProjectPageToggleLoading,
  payload: { isLoading },
});

export const projectFormType = (type: FormType): ActionTypes => ({
  type: ProjectActionTypes.ProjectDetailFormType,
  payload: { type },
});

export const updateProjectViewType = (type: ProjViewType): ActionTypes => ({
  type: ProjectActionTypes.ProjectViewType,
  payload: { type },
});

export const clearProjectstate = (): ActionTypes => ({
  type: ProjectActionTypes.ClearProjectState,
  payload: {},
});
