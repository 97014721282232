import { TYPE_OF_OVERVIEW } from "./overview.types";
import { DialogTypes, OverviewModel } from "./Overview.model";

export const ShowHideValue = {
  InOutTime: "InOutTime",
  Skill: "Skill",
  Project: "Project",
  ProjectColor: "ProjectColor",
  Shift: "Shift",
  TimeSplit: "TimeSplit",
  Leave: "Leave",
  ProjectIncharge: "ProjectIncharge",
  AutoRenewal: "AutoRenewal",
};

export type CellBodyProps = {
  data: OverviewModel;
  isFirstColumn: boolean;
  isLastColumn?: boolean;
  updateModalView: (modalType: DialogTypes) => void;
  onVerifyClick?: () => void;
  viewType: TYPE_OF_OVERVIEW;
};

export type CellTypeProps = {
  data: OverviewModel;
  showBg?: boolean;
  rootClassName?: string;
  contentClassName?: string;
  type: TYPE_OF_OVERVIEW;
  showVerifyBtn?: boolean;
  onVerifyClick?: () => void;
};

export type TotalCellType = {
  ot1Mins: number;
  ot2Mins: number;
  regMins: number;
  totalMins: number;
};
