import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import { EmployeeQuery } from "./Employee.model";
import { ActionTypes, EmployeeState } from "./employee.types";
import {
  employeePageToggleLoading,
  employeeSelected,
  employeesLoaded,
  employeesRequested,
} from "./employee.actions";
import { findEmployees, getEmployeeById } from "./employee.service";
import { constructErrorMessage } from "@biznessforce/ui-lib";

export function* employeesPageRequestSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: EmployeeQuery } };

  const { lastQuery }: { lastQuery: EmployeeQuery } = yield select(
    (state: { employees: EmployeeState }) => state.employees
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(employeePageToggleLoading(true));

  // If query has value add property pageNumber to 1 else {}
  const dummyQuery = Object.is(query, {})
    ? {}
    : { ...query, pageNumber: query?.pageNumber || 1 };

  const finalQuery = { ...lastQuery, ...dummyQuery };

  try {
    const { data, headers } = yield findEmployees(finalQuery);

    // If page has 0 data should navigate to previous page
    if (
      data.length === 0 &&
      parseInt(headers["x-total-count"]) > 0 &&
      finalQuery.pageNumber! > 1
    ) {
      yield put(employeesRequested({ pageNumber: finalQuery.pageNumber! - 1 }));
    } else {
      yield put(
        employeesLoaded({
          query: finalQuery,
          employees: data,
          totalCount: parseInt(headers["x-total-count"]),
        })
      );
    }
    yield put(updateGlobalError(undefined));
  } catch (error) {
    console.log({ error });
    yield put(
      updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
    );
  }
  yield put(ajaxLoader({ isLoading: false }));
}

export function* employeeSelectSaga(action: ActionTypes) {
  const {
    payload: { employeeId, onSuccessCB },
  } = action as any;

  if (employeeId) {
    yield put(ajaxLoader({ isLoading: true }));
    try {
      const { data } = yield getEmployeeById(employeeId);
      yield put(employeeSelected(data));
      yield put(updateGlobalError(undefined));
      yield put(ajaxLoader({ isLoading: false }));
      onSuccessCB(data);
    } catch (error) {
      yield put(ajaxLoader({ isLoading: false }));
      yield put(
        updateGlobalError({ type: "error", msg: constructErrorMessage(error) })
      );
    }
  }
}
