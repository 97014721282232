import { AccountsQuery, User } from "./Account.model";

export type FormType = "EDIT" | "READ_ONLY" | "OVERVIEW_VIEW";

export interface AccountState {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: AccountsQuery;
  formType: FormType;
  accounts: User[];
  selectedAccount?: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appsAndRoles: any[];
  showInitWaitingMessage: boolean;
  accountErrorMessage?: string;
}

export const AccountActionTypes = {
  AccountCreate: "[ACCOUNT] Create Account [API]",
  AccountUpdate: "[ACCOUNT] Update Account [API]",
  AccountDelete: "[ACCOUNT] Delete Account [API]",
  AccountsPageRequested: "[ACCOUNT] Page Requested [API]",
  AsideTypeUpdate: "[ACCOUNT] Update Aside Type [ACTION]",
  AccountDetailFormType: "[ACCOUNT] Detail Form Type [ACTION]",
  AccountAppsAndRolesRequested: "[ACCOUNT] APPS And Roles Requested [API]",
  AccountsPageLoaded: "[ACCOUNT] Page Loaded [ACTION]",
  AccountsPageToggleLoading: "[ACCOUNT] Update Page Loading [ACTION]",
  AccountActionToggleLoading: "[ACCOUNT] Update Action Loading [ACTION]",
  AccountErrorMsg: "[ACCOUNT] Update Error Message [ACTION]",
  AccountSelection: "[ACCOUNT] Select Account [API]",
  AccountSelected: "[ACCOUNT] Account Selected [ACTION]",
  AccountsAppsAndRolesLoaded: "[ACCOUNT] Apps And Roles Loaded [ACTION]",
  ClearAccountState: "[ACCOUNT] Clear Accounts State [ACTION]",
};

interface AccountCreate {
  type: typeof AccountActionTypes.AccountCreate;
  payload: { account: User };
}

interface AccountUpdate {
  type: typeof AccountActionTypes.AccountUpdate;
  payload: { account: User; accountId: string };
}

interface AccountDelete {
  type: typeof AccountActionTypes.AccountDelete;
  payload: { accountId: string };
}

interface AccountsPageRequest {
  type: typeof AccountActionTypes.AccountsPageRequested;
  payload: { query: AccountsQuery };
}

interface AccountsPageLoaded {
  type: typeof AccountActionTypes.AccountsPageLoaded;
  payload: { accounts: User[]; totalCount: number; query: AccountsQuery };
}

interface AccountsErrorMessage {
  type: typeof AccountActionTypes.AccountErrorMsg;
  payload: { errorMessage: string };
}

interface AccountSelection {
  type: typeof AccountActionTypes.AccountSelection;
  payload: { accountId?: string };
}

interface AccountSelected {
  type: typeof AccountActionTypes.AccountSelected;
  payload: { account?: User };
}

interface AccountPageToggleLoading {
  type: typeof AccountActionTypes.AccountsPageToggleLoading;
  payload: { isLoading: boolean };
}

interface AccountActionToggleLoading {
  type: typeof AccountActionTypes.AccountActionToggleLoading;
  payload: { isLoading: boolean };
}

interface AccountAppsAndRolesRequested {
  type: typeof AccountActionTypes.AccountAppsAndRolesRequested;
  payload: { accountId: string; inclRoles: boolean };
}

interface AccountsAppsAndRolesLoaded {
  type: typeof AccountActionTypes.AccountsAppsAndRolesLoaded;
  payload: { apps };
}

interface AccountDetailFormType {
  type: typeof AccountActionTypes.AccountDetailFormType;
  payload: { type: FormType };
}

interface ClearAccountState {
  type: typeof AccountActionTypes.ClearAccountState;
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload: {};
}

export type ActionTypes =
  | AccountsPageLoaded
  | AccountsErrorMessage
  | AccountSelection
  | AccountSelected
  | AccountPageToggleLoading
  | AccountActionToggleLoading
  | AccountsAppsAndRolesLoaded
  | AccountDetailFormType
  | AccountCreate
  | AccountUpdate
  | AccountDelete
  | AccountAppsAndRolesRequested
  | AccountsPageRequest
  | ClearAccountState;
