import { constructErrorMessage } from "@biznessforce/ui-lib";
import { put, select } from "redux-saga/effects";
import { ajaxLoader, updateGlobalError } from "../config/config.actions";
import {
  projOverviewLoaded,
  projOverviewLoading,
} from "./proj_overview.actions";
import { ProjOverview, ProjOverviewQuery } from "./proj_overview.model";
import { fetchProjOverview } from "./proj_overview.service";
import { ActionTypes, ProjOverviewState } from "./proj_overview.types";

export function* ProjOverviewRequestedSaga(action: ActionTypes) {
  const {
    payload: { query },
  } = action as { payload: { query: ProjOverviewQuery } };

  const { lastQuery }: { lastQuery: ProjOverviewQuery } = yield select(
    (state: { projOverview: ProjOverviewState }) => state.projOverview
  );

  yield put(ajaxLoader({ isLoading: true }));
  yield put(projOverviewLoading(true));

  const finalQuery = { ...lastQuery, ...query };

  if (!finalQuery.fromDt) return;

  try {
    const { data }: { data: ProjOverview } = yield fetchProjOverview(
      finalQuery
    );

    const updatedData: ProjOverview = {
      ...data,
      projects: data.projects?.map((d: any) => {
        const { details, ...proj } = d;
        details.unshift(proj);
        return details;
      }),
    };

    yield put(projOverviewLoaded({ dataList: updatedData, query: finalQuery }));
    // totalCount: parseInt(headers['x-total-count']),

    yield put(updateGlobalError(undefined));
  } catch (err) {
    console.log({ err });
    yield put(
      updateGlobalError({
        type: "error",
        msg: constructErrorMessage(err),
      })
    );
  } finally {
    yield put(ajaxLoader({ isLoading: false }));
  }
}
