import { ProjOverviewQuery } from "./proj_overview.model";
import {
  ProjOverviewState,
  ProjOverviewActionTypes,
} from "./proj_overview.types";

const initialProjState: ProjOverviewState = {
  listLoading: false,
  dataList: { projects: [] },
  lastQuery: new ProjOverviewQuery(),
};

export const projOverviewReducer = (
  state = initialProjState,
  { type, payload }: any
): ProjOverviewState => {
  switch (type) {
    case ProjOverviewActionTypes.ProjOverviewPageToggleLoading: {
      return { ...state, listLoading: payload.isLoading };
    }
    case ProjOverviewActionTypes.ProjOverviewPageLoaded:
      return {
        ...state,
        dataList: payload.dataList,
        listLoading: false,
        lastQuery: { ...state.lastQuery, ...payload.query },
      };
    case ProjOverviewActionTypes.ClearProjOverviewState: {
      state = initialProjState;
      return { ...state };
    }
    default:
      return { ...state };
  }
};
