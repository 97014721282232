import { ActionTypes, CustomerActionTypes, FormType } from "./customer.types";
import { Customer, CustomerQuery } from "./customer.model";

export const customerRequested = (query: CustomerQuery): ActionTypes => ({
  type: CustomerActionTypes.CustomerPageRequested,
  payload: { query },
});

export const customerPageToggleLoading = (isLoading: boolean): ActionTypes => ({
  type: CustomerActionTypes.CustomerPageToggleLoading,
  payload: { isLoading },
});

export const customerLoaded = ({
  customers,
  totalCount,
  query,
}: {
  customers: Customer[];
  totalCount: number;
  query: CustomerQuery;
}): ActionTypes => ({
  type: CustomerActionTypes.CustomerPageLoaded,
  payload: { customers, totalCount, query },
});

export const customerSelect = (
  partyId: string,
  onSuccessCB: (data) => void
): ActionTypes => ({
  type: CustomerActionTypes.CustomerSelection,
  payload: { partyId, onSuccessCB },
});

export const customerSelected = (customer?: Customer): ActionTypes => ({
  type: CustomerActionTypes.CustomerSelected,
  payload: { customer },
});

export const customerFormType = (type: FormType): ActionTypes => ({
  type: CustomerActionTypes.CustomerDetailFormType,
  payload: { type },
});
